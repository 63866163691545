import ReMapped from "../../assets/js/reMapped";

const reMapped = new ReMapped();

export default {
  methods: {
    initMain() {
      reMapped.init();
    },
    initSaioMap() {
      // reMapped.initGoogleMap();
    },
    initMaterilizeComponents() {
      reMapped.initMapSaioView();
    },
    initMapSaioView() {
      // reMapped.initGoogleMap();
    },
    initPopupSlide() {
      reMapped.executeOtherPopupSlide();
    },
    initCulqiJS() {
      reMapped.initCulqi();
    },
    initModalStart() {
      reMapped.initModalStart();
    },
  },
};
