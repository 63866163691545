const servicePackages = {
  programmed: [
    {
      id: "Pequeño",
      title: "Paquete Pequeño",
      description: "PEQUEÑO",
      imgUrl: "~@/assets/img/icon/icon-small.svg",
      packageSize: {
        height: 10, //Altura
        lengths: 28, //Largo
        width: 35, // Ancho
      },
    },
    {
      id: "Mediano",
      title: "Paquete Mediano",
      description: "MEDIANO",
      imgUrl: "~@/assets/img/icon/icon-medium.svg",
      packageSize: {
        height: 20, //Altura
        lengths: 30, //Largo
        width: 40, // Ancho
      },
    },
    {
      id: "Grande",
      title: "Paquete Grande",
      description: "GRANDE",
      imgUrl: "~@/assets/img/icon/icon-grand.svg",
      packageSize: {
        height: 40, //Altura
        lengths: 30, //Largo
        width: 40, // Ancho
      },
    },
    {
      id: "Personalizado",
      title: "Paquete Personalizado",
      description: "OTRO",
      imgUrl: "~@/assets/img/icon/icon-other.svg",
      packageSize: {
        height: 0, //Altura
        lengths: 0, //Largo
        width: 0, // Ancho
        weight: 0, //peso
      },
    },
  ],
  scharffPoint: [
    {
      id: "Pequeño",
      title: "Paquete Pequeño",
      description: "PEQUEÑO",
      imgUrl: "~@/assets/img/icon/icon-small.svg",
      packageSize: {
        height: 10, //Altura
        lengths: 28, //Largo
        width: 35, // Ancho
      },
    },
    {
      id: "Mediano",
      title: "Paquete Mediano",
      description: "MEDIANO",
      imgUrl: "~@/assets/img/icon/icon-medium.svg",
      packageSize: {
        height: 20, //Altura
        lengths: 30, //Largo
        width: 40, // Ancho
      },
    },
    {
      id: "Grande",
      title: "Paquete Grande",
      description: "GRANDE",
      imgUrl: "~@/assets/img/icon/icon-grand.svg",
      packageSize: {
        height: 40, //Altura
        lengths: 30, //Largo
        width: 40, // Ancho
      },
    },
    {
      id: "Personalizado",
      title: "Paquete Personalizado",
      description: "OTRO",
      imgUrl: "~@/assets/img/icon/icon-other.svg",
      packageSize: {
        height: 0, //Altura
        lengths: 0, //Largo
        width: 0, // Ancho
        weight: 0, //peso
      },
    },
  ],
};

export default servicePackages;
