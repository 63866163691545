
export default {
  methods: {
    trackEvent(eventName, eventCategory, value = 1) {

      let eventParams = { 
        'event-category': eventCategory,
        'value': value,
        'page-location': window.location.href
      }      
      // console.log(eventName)
      // console.log(eventParams);

      if (typeof gtag === 'function') {
        gtag('event', eventName, eventParams);
      } else {
        console.warn('Tracking attempted before gtag.js is initialized');
      }
    }
  }
};
