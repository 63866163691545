/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
import M from "materialize-css";
import servicePackages from "../../assets/js/servicePackages";

const availableProvinces = [
  128, //Lima
  67, //Callao
];

const isles = [
  //Arequipa
  [
    2224,
    2225,
    2226,
    2227,
    2228,
    2229,
    2230,
    2231,
    2232,
    2233,
    2234,
    2235,
    2236,
    2237,
    2238,
    2239,
    2240,
    2241,
    2242,
    2243,
    2244,
    2245,
    2246,
    2247,
    2248,
    2249,
    2250,
    2251,
    2252,
    2253,
    2254,
    2255,
    2257,
    2258,
    2259,
    2260,
    2261,
    2262,
    2263,
    2264,
    2265,
    2266,
    2267,
    2268,
    2269,
    2270,
    2271,
    2272,
    2273,
    2274,
    2275,
    2276,
    2277,
    2278,
    2279,
    2280,
    2281,
    2282,
    2283,
    2284,
    2285,
    2286,
    2287,
    2288,
    2289,
    2290,
    2291,
    2292,
    2293,
    2294,
    2295,
    2296,
    2297,
    2298,
    2299,
    2300,
    2301,
    2302,
    2303,
    2304,
    2306,
    2307,
    2308,
    2309,
    2310,
    2311,
    2312,
    2313,
    2314,
    2315,
    2316,
    2317,
    2318,
    2319,
    2320,
    2321,
    2322,
    2323,
    2324,
    2325,
    2326,
    2327,
    2328,
    2329,
    2330,
    2331,
    2332,
    2333,
    2334
  ],
  //Trujillo
  /*[
    3067,
    3068,
    3069,
    3070,
    3071,
    3072,
    3073,
    3074,
    3075,
    3076,
    3077
  ],*/
]


const utils = {
  methods: {
    getCurrentStringDate() {
      let today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
      const yyyy = today.getFullYear();

      today = `${dd}/${mm}/${yyyy}`;
      return today;
    },
    getServicePackageSize(serviceType, packageType) {
      // 1 -> express
      // 2 -> programado
      // 3 -> cyt
      // 4 -> punto scharff
      let packages = null;
      let packageSize = null;
      if (packageType) {
        if (serviceType === 2) {
          packages = servicePackages.programmed;
        } else if (serviceType === 4) {
          packages = servicePackages.scharffPoint;
        } else {
          return null;
        }

        packageSize = packages.find(
          (x) => x.id.toUpperCase() === packageType.toUpperCase()
        )?.packageSize;
        return packageSize;
      }
      return null;
    },
    catchStandardErrorMsg(error) {
      let errorMsg = "Ocurrio un error inesperado";
      if (error) {
        if (error.message) {
          errorMsg = error.message;
        } else if (error.data.message) {
          errorMsg = error.data.message;
        }
      }
      return errorMsg;
    },
    initTabs() {
      const el = document.querySelector(".tabs");
      M.Tabs.init(el);
    },
    initSelects() {
      // eslint-disable-next-line camelcase
      const elems_select = document.querySelectorAll(".c-select select");
      M.FormSelect.init(elems_select);
    },
    closeModal(id) {
      const modal = document.querySelector(`#${id}`);
      const instance = M.Modal.init(modal, { dismissible: false });
      instance.close();
    },
    openModal(id) {
      const modal = document.querySelector(`#${id}`);
      if (!modal) return;
      const instance = M.Modal.init(modal, { dismissible: false });
      instance.open();
    },
    openModalDismissible(id) {
      const modal = document.querySelector(`#${id}`);
      const instance = M.Modal.init(modal, { dismissible: true });
      instance.open();
    },
    showWarning(msg) {
      this.$swal({
        title: "Advertencia!",
        html: msg,
        icon: "warning",
        confirmButtonText: "Aceptar",
      });
    },

    showError(msg, title, onConfirm) {
      this.$swal({
        title: title ?? "Error!",
        html: msg,
        icon: "error",
        confirmButtonText: "Aceptar",
      }).then(() => {
        if (onConfirm) {
          onConfirm();
        }
      });
    },
    showOk(msg, onConfirm) {
      this.$swal({
        title: "OK!",
        html: msg,
        icon: "success",
        confirmButtonText: "Aceptar",
      }).then(() => {
        if (onConfirm) {
          onConfirm();
        }
      });
    },
    showOk_2(msg, onConfirm, denyButtonText, title = "OK!") {
      this.$swal({
        title,
        html: msg,
        icon: "success",
        confirmButtonText: "Aceptar",
        showDenyButton: true,
        denyButtonText,
        preDeny() {
          window.print();
          return false;
        },
        denyButtonColor: "black",
      }).then((result) => {
        if (result.isConfirmed) {
          if (onConfirm) {
            onConfirm();
          }
        } else if (result.isDenied) {
          window.print();
        }
      });
    },
    showOk_3(msg, onConfirm) {
      this.$swal({
        title: "OK!",
        html: msg,
        icon: "success",
        confirmButtonText: "Aceptar",
        showDenyButton: true,
        denyButtonText: "Cancelar",
      }).then(() => {
        if (onConfirm) {
          onConfirm();
        }
      });
    },
    showLoading(text) {
      this.$swal({
        text: text ?? "Procesando...",
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
    },

    onlyNumbers($event) {
      const keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },

    removeLettersOnPaste($event) {
      const value = $event.clipboardData.getData("text/plain");

      // eslint-disable-next-line prefer-regex-literals
      if (RegExp(/[^0-9.]/g, "").test(value)) {
        $event.target.value = value.replace(/[^0-9]/g, "");
        $event.preventDefault();
      }
    },

    removeLettersOnPaste2($event, field, field2) {
      $event.preventDefault();
      const value = $event.clipboardData.getData("text/plain");
      const parsed = value.replace(/[^0-9]/g, "");
      if (field2) {
        this[field][field2] = parsed;
      } else {
        this[field] = parsed;
      }

      // eslint-disable-next-line prefer-regex-literals
      // if (RegExp(/[^0-9.]/g, "").test(value)) {
      //   $event.target.value = value.replace(/[^0-9]/g, "");
      // }
    },

    validateEmail(email) {
      if (
        // eslint-disable-next-line prefer-regex-literals
        RegExp(
          // eslint-disable-next-line max-len
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
        ).test(email)
      ) {
        return true;
      }
      return false;
    },

    timeout(ms) {
      // eslint-disable-next-line no-promise-executor-return
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    getSuccessTextTokenization(response) {
      if (!response.isNiubizError) {
        return `
        <b>nro. de pedido:</b> ${response.purchaseNumber} </br>
        <b>nombre y apellido del cliente:</b> ${response.firstName} ${response.lastName} </br>
        <b>nro. de tarjeta:</b> ${response.cardNumber} </br>
        <b>marca de la tarjeta:</b> ${response.cardBrand} </br>
        <b>fecha y hora del pedido:</b> ${response.date} </br>
        <b>términos y condiciones:</b> <a
        href="https://saio.holascharff.com/Files/terminos-y-condiciones-tarjeta.pdf"
        target="popup"
        onclick="window.open('https://saio.holascharff.com/Files/terminos-y-condiciones-tarjeta.pdf','popup','width=600,height=600'); return false;"
        style="
        color: rgb(255, 0, 0);
        text-decoration: underline;
        "
        >click aquí</a>
        </br>             
        `;
      }
      return `
        Motivo de denegación: ${response.actionDescription} </br>
        Número de pedido: ${response.purchaseNumber} </br>
        Fecha y hora del pedido: ${response.date} </br>
        Tipo de moneda: ${response.currency} </br>        
        `;
    },
    getErrorTextTokenization(response) {
      return `
      <h2>Error al agregar tarjeta</h2>
      Número de pedido: ${response.purchaseNumber} </br>
      Fecha y hora del pedido: ${response.date} </br>
      `;
    },
    generatePurchaseNumber() {
      return Math.floor(Math.random() * 10000000000).toString();
    },
    print() {
      window.print();
    },
    validateInsideIsle(originDistrictId, destinationDistrictId) {
      let isInside = false;
      isles.forEach((isle) => {
        if (isle.includes(originDistrictId) && isle.includes(destinationDistrictId)) {
          isInside = true;
        }
      });
      return isInside;
    },
    validateOriginDestination(originProvinceId, destinationProvinceId) {
      if (availableProvinces.includes(originProvinceId) || availableProvinces.includes(destinationProvinceId)) {
        return true;
      }
      return false;
    },
    toCapitalCase(str) {
      return str.toLowerCase().split(' ').map(function(word) {
        return word.charAt(0).toUpperCase() + word.substr(1)
      }).join(' ')
    },
  },
};

export default utils;
