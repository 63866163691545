/* eslint-disable no-undef */

/*
|--------------------------------------------------------------------------
| Javascript Util function
|--------------------------------------------------------------------------
|
*/
class UtilApp {

    /*
     * Convertir SVG a código HTML y XML
     */

    hasClass(el, className) {
        if (el && className) {
            if (el && el.classList) return el.classList.contains(className);
            else
                return !!el.className.match(
                    new RegExp("(\\s|^)" + className + "(\\s|$)")
                );
        }

    }

    addClass(el, className) {
        if (el && className) {
            if (el && el.classList) el.classList.add(className);
            else if (!this.hasClass(el, className)) el.className += " " + className;
        }
    }

    removeClass(el, className) {

        if (el && className) {
            if (el && el.classList) el.classList.remove(className);
            else if (this.hasClass(el, className)) {
                var reg = new RegExp("(\\s|^)" + className + "(\\s|$)");
                el.className = el.className.replace(reg, " ");
            }
        }
    }

}

export default UtilApp;