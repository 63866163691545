export default {
  methods: {
    closeSidebar() {

      let elems_sidenav = document.querySelectorAll(".sidenav");
      let instances_sidenav = M.Sidenav.init(elems_sidenav);
      
      const sidebar = document.querySelector(".sidenav-overlay");

      if (sidebar) {
        sidebar.style.display = "none";
        sidebar.style.opacity = 0;
      }


    },
  },
};
