<template>
  <div id="app">
    <!-- <splash-effect style="z-index: 999999; position: absolute;"/> -->
    <router-view name="a" />
    <!--<modal-start-slider />-->
    <!-- <modal-scharff-real-plaza id="modalScharffRealPlaza" /> -->
    <modal-quoter-home />
    <ProductMarketFitSurvey :serviceType="serviceType" />
    <ModalCompleteData />
  </div>
</template>

<script>
import ModalQuoterHome from "./views/modals/ModalQuoterHome.vue";
import ModalStartSlider from "./components/Modals/ModalStartSlider.vue";
import ModalScharffRealPlaza from "@/components/Modals/ModalScharffRealPlaza.vue";
import ProductMarketFitSurvey from "./views/modals/ProductMarketFitSurvey.vue";
import ModalCompleteData from "./views/modals/ModalCompleteData.vue"

// import SplashEffect from '@/components/General/SplashEffect.vue';


/* eslint-disable no-undef */
export default {
  name: "App",
  components: {
    ModalQuoterHome,
    ModalStartSlider,
    ModalScharffRealPlaza,
    ProductMarketFitSurvey,
    ModalCompleteData,
    // SplashEffect
  },
  mounted() {
    // GOOGLE MAPS SCRIPT //
    // const position = {
    //   lat: 12.0558393,
    //   lng: -77.0717681,
    // };
    const script = document.createElement("script");
    const mapsApiKey = process.env.VUE_APP_MAPS_API_KEY;
    script.src =
      `https://maps.googleapis.com/maps/api/js?key=${mapsApiKey}&libraries=places`;
    document.head.appendChild(script);
    script.onload = () => {
      // The map, centered at Uluru
      // const map = new window.google.maps.Map(
      //   document.getElementById("mapSaio"),
      //   {
      //     zoom: 4,
      //     center: position,
      //   }
      // );
      // The marker, positioned at Uluru
      // const marker = new window.google.maps.Marker({
      //   position,
      //   map,
      // });
      // // The map, centered at Uluru
      // const map2 = new window.google.maps.Map(
      //   document.getElementById("mapSaioView"),
      //   {
      //     zoom: 4,
      //     center: position,
      //   }
      // );
      // // The marker, positioned at Uluru
      // const marker2 = new window.google.maps.Marker({
      //   position,
      //   map,
      // });
    };
    /// ///////////////////

    this.$nextTick(() => {
      this.initTabs();
      // this.initMaterilizeComponents();
    });

  },
  updated() {},
  computed: {
    serviceType() {
      return this.$store.getters.SERVICE_TYPE_FOR_SURVEY;
    },
  },
};
</script>

<style lang="scss"></style>
