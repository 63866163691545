/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import UtilApp from "../plugins/general/util";
import M from "materialize-css";
import axios from "axios";
const Util = new UtilApp();

const axiosInstance = axios.create({
  withCredentials: false,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
});

const mapsApiKey = process.env.VUE_APP_MAPS_API_KEY;
const apiMap =
  `https://maps.googleapis.com/maps/api/js?key=${mapsApiKey}&libraries=places`;
  
// OTHER FUNCTIONS

function executeSearchPointScharff() {
  let $inputSearchPoints_autocomplete = document.querySelector(
    "#c-input-search-points"
  );
  let $buttonListPoints = document.querySelector("#c-button-list-points");

  $inputSearchPoints_autocomplete.addEventListener("change", function (e) {
    let loadPoints = document.getElementById("c-load-points");
    let searchPoints = document.getElementById("c-search-points");

    Util.removeClass(loadPoints, "c-hidden");
    Util.addClass(searchPoints, "c-hidden");

    setTimeout(() => {
      Util.addClass(loadPoints, "c-hidden");
      Util.removeClass(searchPoints, "c-hidden");
    }, 1000);

    return false;
  });

  $buttonListPoints.addEventListener("click", function (e) {
    let $mapPoint = document.querySelector(
      ".c-template--app__container--map-point"
    );

    let existFullView = false;
    let classFullView = "c-template--app__container--map-point--fullview";

    existFullView = Util.hasClass($mapPoint, classFullView);

    if (existFullView) {
      Util.removeClass($mapPoint, classFullView);
    } else {
      Util.addClass($mapPoint, classFullView);
    }
  });
}

function executeOtherInitMap() {
  let mapsaio = document.getElementById("mapSaio");

  const position = {
    lat: 12.0558393,
    lng: -77.0717681,
  };

  if (mapsaio) {
    setTimeout(() => {
      // The map, centered at Uluru
      const map = new google.maps.Map(document.getElementById("mapSaio"), {
        zoom: 4,
        center: position,
        fullscreenControl: true
      });
      // The marker, positioned at Uluru
      const marker = new google.maps.Marker({
        position: position,
        map: map,
      });
    }, 300);
  }
}

function executeOtherInitMapSaioView() {
  let mapSaioView = document.getElementById("mapSaioView");

  const position = {
    lat: 12.0558393,
    lng: -77.0717681,
  };

  if (mapSaioView) {
    setTimeout(() => {
      // The map, centered at Uluru
      const map = new google.maps.Map(document.getElementById("mapSaioView"), {
        zoom: 4,
        center: position,
        fullscreenControl: true
      });
      // The marker, positioned at Uluru
      const marker = new google.maps.Marker({
        position: position,
        map: map,
      });
    }, 300);
  }
}

function executeOtherInit() {
  let collectionTypeDoc2 = document.getElementsByClassName(
    "collection-item-point"
  );
  let typeDocList2 = document.getElementsByClassName("c-type-point");

  if (typeDocList2.length !== 0) {
    let clickCollectionTypeDoc2 = () => {
      for (var i = 0; i < collectionTypeDoc2.length; i++) {
        Util.removeClass(collectionTypeDoc2[i], "active");
      }
      this.querySelector("input").checked = true;
      Util.addClass(this, "active");
    };

    for (var i = 0; i < collectionTypeDoc2.length; i++) {
      collectionTypeDoc2[i].addEventListener(
        "click",
        clickCollectionTypeDoc2,
        false
      );
    }
  }

  /*
   * Demo Click Select Type Doc
   */

  let collectionTypeDoc = document.getElementsByClassName("collection-item");
  let typeDocList = document.getElementsByClassName("c-type-doc-list");
  let ImagetypoDoc = document.getElementsByClassName("c-box--type-doc");

  if (typeDocList.length !== 0) {
    let clickCollectionTypeDoc = () => {
      let attribute = this.getAttribute("data-img");
      let ContainerImageTypeDoc = document.getElementById(attribute);

      for (var i = 0; i < collectionTypeDoc.length; i++) {
        Util.removeClass(collectionTypeDoc[i], "active");
        Util.removeClass(ImagetypoDoc[i], "active");
      }
      this.querySelector("input").checked = true;

      Util.addClass(this, "active");
      Util.addClass(ContainerImageTypeDoc, "active");
    };

    for (i = 0; i < collectionTypeDoc.length; i++) {
      collectionTypeDoc[i].addEventListener(
        "click",
        clickCollectionTypeDoc,
        false
      );
    }
  }

  /*
   * Demo: click modal Add direction
   */

  let btnOpenModalDisable = document.querySelector("#btnOpenModalDisable");
  let modalDisabled = document.querySelector("#modalDisabled");

  if (btnOpenModalDisable) {
    btnOpenModalDirections.onclick = () => {
      var instance = M.Modal.getInstance(modalDirections);
      if (instance) instance.open();
    };
  }

  let btnOpenModalDirections = document.querySelector(
    "#btnOpenModalDirections"
  );
  let btnOpenModalDirecitionClass = document.querySelectorAll(
    ".btn-open-modal-direction"
  );
  let modalDirections = document.querySelector("#modalDirections");

  if (btnOpenModalDirections) {
    btnOpenModalDirections.onclick = () => {
      var instance = M.Modal.getInstance(modalDirections);
      if (instance) instance.open();
    };
  }

  if (btnOpenModalDirecitionClass) {
    btnOpenModalDirecitionClass.forEach((boton) => {
      boton.addEventListener("click", () => {
        var instanceModalDirections = M.Modal.getInstance(modalDirections);
        if (instanceModalDirections) instanceModalDirections.open();
      });
    });
  }

  /*
   * Demo: Modal demo Búsqueda filtro
   */

  let btnOpenModalSearch = document.querySelector("#btnOpenModalSearch");
  let modalSearch = document.querySelector("#modalSearch");

  if (btnOpenModalSearch) {
    btnOpenModalSearch.onclick = () => {
      var instance = M.Modal.getInstance(modalSearch);
      if (instance) instance.open();
    };
  }

  let btnOpenModalContacts = document.querySelector("#btnOpenModalContatcs");
  let modalContacts = document.querySelector("#modalContact");

  if (btnOpenModalContacts) {
    btnOpenModalContacts.addEventListener("click", () => {
      let instance3 = M.Modal.getInstance(modalContacts);
      if (instance3) instance3.open();
    });
  }

  let btnOpenModalCardvisa = document.querySelectorAll("#btnOpenModalCardvisa");
  let modalCardvisa = document.querySelector("#modalCardvisa");

  if (btnOpenModalCardvisa) {
    btnOpenModalCardvisa.forEach((items) => {
      items.addEventListener("click", () => {
        var instance2 = M.Modal.getInstance(modalCardvisa);
        if (instance2) instance2.open();
      });
    });
  }

  /*
   * Demo: Modal Demo Cupon
   */

  let btnOpenModalCupon = document.querySelectorAll("#btnOpenModalCupon");
  let modalCupon = document.querySelector("#modalCupon");

  if (btnOpenModalCupon) {
    btnOpenModalCupon.forEach((items) => {
      items.addEventListener("click", () => {
        var instance2 = M.Modal.getInstance(modalCupon);
        if (instance2) instance2.open();
      });
    });
  }

  /*
   * Demo: Modal Demo Envio de paquete
   */

  let btnOpenSendPackage = document.querySelectorAll("#btnOpenSendPackage");
  let OpenSendPackage = document.querySelector("#OpenSendPackage");

  if (btnOpenSendPackage) {
    btnOpenSendPackage.forEach((items) => {
      items.addEventListener("click", () => {
        var instance2 = M.Modal.getInstance(OpenSendPackage);
        if (instance2) instance2.open();
      });
    });
  }

  /*
   * Demo: Modal Demo Voucher
   */

  let btnOpenModalTypeVoucher = document.querySelectorAll(
    "#btnOpenModalTypeVoucher"
  );
  let modalTypeVoucher = document.querySelector("#modalTypeVoucher");

  if (btnOpenModalTypeVoucher) {
    btnOpenModalTypeVoucher.forEach((items) => {
      items.addEventListener("click", () => {
        var instance2 = M.Modal.getInstance(modalTypeVoucher);
        if (instance2) instance2.open();
      });
    });
  }

  /*
   * Demo: Modal Demo Tarjeta
   */

  let btnOpenModalTypeCard = document.querySelectorAll("#btnOpenModalTypeCard");
  let modalTypeCard = document.querySelector("#modalTypeCard");

  if (btnOpenModalTypeCard) {
    btnOpenModalTypeCard.forEach((items) => {
      items.addEventListener("click", () => {
        var instance2 = M.Modal.getInstance(modalTypeCard);
        if (instance2) instance2.open();
      });
    });
  }

  /*
   * Demo: Modal Demo Propina
   */

  let btnOpenModalTip = document.querySelectorAll("#btnOpenModalTip");
  let modalTip = document.querySelector("#modalTip");

  if (btnOpenModalTip) {
    btnOpenModalTip.forEach((items) => {
      items.addEventListener("click", () => {
        var instance2 = M.Modal.getInstance(modalTip);
        if (instance2) instance2.open();
      });
    });
  }

  /*
   * Demo: Evento click para redireccionamiento en A donde lo llevamos por ti
   */

  let btnSet = document.getElementById("btnOpenModalSetAdress");
  if (btnSet) {
    btnSet.disabled = false;
  }
  let pickSearch = document.getElementById("pickSearch");

  if (pickSearch) {
    pickSearch.addEventListener("click", () => {
      window.location.href = "pick-express-search.html";
    });
  }
  let sendSearch = document.getElementById("sendSearch");
  if (sendSearch) {
    sendSearch.addEventListener("click", () => {
      window.location.href = "send-express-search.html";
    });
  }

  /*
   * Demo: Modal Demo Restricción
   */

  let btnOpenModalRestriction = document.querySelectorAll(
    "#btnOpenModalRestriction"
  );
  let modalRestriction = document.querySelector("#modalRestriction");

  if (btnOpenModalRestriction) {
    btnOpenModalRestriction.forEach((items) => {
      items.addEventListener("click", () => {
        let instance3 = M.Modal.getInstance(modalRestriction);
        if (instance3) instance3.open();
      });
    });
  }

  //Modal 27 01 2022
  let btnOpenModalRestrictionMovil = document.querySelector(
    "#btnOpenModalRestrictionMovil"
  );
  let modalRestriction2 = document.querySelector("#modalRestriction");

  if (btnOpenModalRestrictionMovil) {
    btnOpenModalRestrictionMovil.addEventListener("click", () => {
      let instance3 = M.Modal.getInstance(modalRestriction2);
      if (instance3) instance3.open();
    });
  }

  let btnOpenModalCTRestriction = document.querySelectorAll(
    "#btnOpenModalCTRestriction"
  );
  let modalCTRestriction = document.querySelector("#modalRestriction");

  if (btnOpenModalCTRestriction) {
    btnOpenModalCTRestriction.forEach((items) => {
      items.addEventListener("click", () => {
        let instance3 = M.Modal.getInstance(modalCTRestriction);
        if (instance3) instance3.open();
      });
    });
  }

  /*
   * Demo: Modal Demo Calculator
   */

  let btnOpenModalCalculatorProgrammed = document.querySelector(
    "#btnOpenModalCalculatorProgrammed"
  );
  let modalCalculatorProgrammed = document.querySelector("#modalCalculator");

  if (btnOpenModalCalculatorProgrammed) {
    btnOpenModalCalculatorProgrammed.addEventListener("click", () => {
      let instance3 = M.Modal.getInstance(modalCalculatorProgrammed);
      if (instance3) instance3.open();
    });
  }

  let btnOpenModalCalculatorCompraTrae = document.querySelector(
    "#btnOpenModalCalculatorCompraTrae"
  );
  let modalCalculatorCompraTrae = document.querySelector("#modalCalculator");

  if (btnOpenModalCalculatorCompraTrae) {
    btnOpenModalCalculatorCompraTrae.addEventListener("click", () => {
      let instance3 = M.Modal.getInstance(modalCalculatorCompraTrae);
      if (instance3) instance3.open();
    });
  }

  let btnOpenModalContentDate = document.querySelector(
    "#btnOpenModalContentDate"
  );
  let modalContentDate = document.querySelector("#modalContentDate");

  if (btnOpenModalContentDate) {
    btnOpenModalContentDate.addEventListener("click", () => {
      let instance3 = M.Modal.getInstance(modalContentDate);
      if (instance3) instance3.open();
    });
  }

  let btnOpenModalContentDateUser = document.querySelector(
    ".btnOpenModalContentDateUser"
  );
  let modalContentDateRegister = document.querySelector(
    "#modalContentDateRegister"
  );

  if (btnOpenModalContentDateUser) {
    btnOpenModalContentDateUser.addEventListener("click", () => {
      let instance3 = M.Modal.getInstance(modalContentDateRegister);
      if (instance3) instance3.open();
    });
  }

  let btnOpenModalEditPackage = document.querySelector(
    "#btnOpenModalEditPackage"
  );
  let modalEditPackage = document.querySelector("#modalEditPackage");

  if (btnOpenModalEditPackage) {
    btnOpenModalEditPackage.addEventListener("click", () => {
      let instance3 = M.Modal.getInstance(modalEditPackage);
      if (instance3) instance3.open();
    });
  }
  /*
   * Demo: Modal Demo Propina
   */

  let btnOpenModalProduct = document.querySelector("#btnOpenModalProduct");
  let modalProduct = document.querySelector("#modalProduct");
  if (btnOpenModalProduct) {
    btnOpenModalProduct.addEventListener("click", () => {
      let instance3 = M.Modal.getInstance(modalProduct);
      if (instance3) instance3.open();
    });
  }

  /*
   * Demo: Modal Calendar
   */

  let btnOpenModalCalendar = document.querySelector("#c-btn-open-calendar");
  let modalCalendar = document.querySelector("#c-modal-calendar");
  if (btnOpenModalCalendar) {
    btnOpenModalCalendar.addEventListener("click", () => {
      let instance3 = M.Modal.init(modalCalendar);
      if (instance3) instance3.open();
    });
  }

  /*
   * Demo: Modal Demo Card Opciones de Éxito y Fall
   */

  let modalCardOptionOpen = document.querySelector("#modalCardOptionOpen");
  let modalCardOption = document.querySelector("#modalCardOption");
  if (modalCardOptionOpen) {
    modalCardOptionOpen.addEventListener("click", () => {
      let instance3 = M.Modal.init(modalCardOption);
      if (instance3) instance3.open();
    });
  }

  /*
   * Demo: Modal Agregar Paquete(Programado)
   */

  let modalAddPackageOpen = document.querySelector("#modalAddPackageOpen");
  let modalAddPackage = document.querySelector("#modalAddPackage");
  if (modalAddPackageOpen) {
    modalAddPackageOpen.addEventListener("click", () => {
      let instance3 = M.Modal.init(modalAddPackage);
      if (instance3) instance3.open();
    });
  }

  /*
   * Demo: Search
   */

  const inputSearchDirection2 = document.getElementById(
    "c-focus-open-modal-search-direction-send"
  );
  const textareaReference = document.getElementById("c-txtarea-reference");

  const searchContainer = document.getElementById("c-search-container");
  const containerAppView = document.getElementById("c-container-app-view");
  const btnContactClick = document.querySelectorAll(".c-btn-contact-click");
  const iconFavorite2 = document.getElementById("c-icon-favorite-2");
  const contactSend2 = document.getElementById("c-contact-send-2");
  const btnContactForm1 = document.getElementById("c-btn-contact-form-1");
  const btnShowContact1 = document.getElementById("c-btn-show-contact-1");

  if (inputSearchDirection2) {
    if (inputSearchDirection2) {
      inputSearchDirection2.addEventListener("focusin", (event) => {
        Util.removeClass(searchContainer, "c-hidde");
        Util.removeClass(iconFavorite2, "c-hidden");

        if (window.matchMedia("(max-width: 767px)").matches) {
          Util.addClass(containerAppView, "full-height");
        }
      });
    }

    if (inputSearchDirection2) {
      inputSearchDirection2.addEventListener("focusout", (event) => {
        Util.removeClass(containerAppView, "full-height");
      });
    }
  }

  if (searchContainer) {
    searchContainer.addEventListener("click", (event) => {
      Util.addClass(searchContainer, "c-hidde");

      inputSearchDirection2.value = "Jirón los médicos Mz 214";
      M.updateTextFields();

      let modalContactFavorite1 = document.querySelector(
        "#c-modal-contact-favorite-1"
      );
      let instance = M.Modal.init(modalContactFavorite1);
      if (instance) instance.open();

      Util.removeClass(textareaReference, "c-hidden");
    });

    btnContactForm1.addEventListener("click", (event) => {
      let modalContactForm1 = document.querySelector("#c-modal-contact-form-1");
      let instance = M.Modal.init(modalContactForm1);
      if (instance) instance.open();
    });

    btnShowContact1.addEventListener("click", (event) => {
      nprogress.start();
      Util.removeClass(contactSend2, "c-hidden");
      //Util.addClass(btnModalContactFavorite, 'c-hidden');

      nprogress.done();
    });

    btnContactClick.forEach((btn) => {
      let modalNoLogin = document.querySelector(
        "#c-modal-contact-form-with-data"
      );
      btn.addEventListener("click", () => {
        let instance = M.Modal.init(modalNoLogin);
        if (instance) instance.open();
        return false;
      });
    });
  }

  // Tab Agregando Paquetes
  let navList = document.getElementById("navList");

  if (navList) {
    let btns = navList.getElementsByClassName(
      "item-package-proof w-100 color-black"
    );

    for (let i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", () => {
        let current = document.getElementsByClassName(
          "item-package-proof w-100 color-black active-package"
        );
        current[0].className = current[0].className?.replace(
          " active-package",
          ""
        );
        if (this) this.className += " active-package";
      });
    }
  }

  /*
   * Demo: Modal  no sesion
   */

  let btnShowModalNoSesion = document.querySelectorAll(".btnShowModalNoSesion");
  let modalNoLogin = document.querySelector("#modalNoLogin");

  if (btnShowModalNoSesion) {
    btnShowModalNoSesion.forEach((btn) => {
      btn.addEventListener("click", () => {
        let instance3 = M.Modal.init(modalNoLogin);
        if (instance3) instance3.open();
        return false;
      });
    });
  }

  /*
   * Demo: Modal Calculator
   */

  let btnShowModalCalculatorBuyAndBring = document.querySelector(
    "#btnShowModalCalculatorBuyAndBring"
  );
  let btnShowModalCalculatorScheduledDelivery = document.querySelector(
    "#btnShowModalCalculatorScheduledDelivery"
  );

  let modalCalculatorBuyAndBring = document.querySelector(
    "#modalCalculatorBuyAndBring"
  );
  let modalCalculatorScheduledDelivery = document.querySelector(
    "#modalCalculatorScheduledDelivery"
  );

  if (btnShowModalCalculatorBuyAndBring) {
    btnShowModalCalculatorBuyAndBring.addEventListener("click", () => {
      let instance = M.Modal.init(modalCalculatorBuyAndBring);
      if (instance) instance.open();
    });
  }

  // if (btnShowModalCalculatorScheduledDelivery) {
  //   btnShowModalCalculatorScheduledDelivery.addEventListener("click", () => {
  //     let instance = M.Modal.init(modalCalculatorScheduledDelivery);
  //     if (instance) instance.open();
  //   });
  // }

  /*
   * Demo: Modal Calculator
   */

  const btnNextPointSharff = document.getElementById("btn-next-point-sharff");

  if (btnNextPointSharff) {
    btnNextPointSharff.addEventListener("click", (event) => {
      let option = document.getElementsByName("option-shipping-type");
      let selectOption = "";
      for (i = 0; i < option.length; i++) {
        if (option[i].checked) selectOption = option[i].value;
      }

      if (selectOption === "recojo") {
        window.location.href = "PointSharffUbication.html";
      } else {
        window.location.href = "PointSharffMeeting.html";
      }
    });
  }
}

function executeOtherPopupSlide() {
  /*
    $(".js-open-popup-slide").click(function (e) {
        e.preventDefault();
        let $popup = $(`#${$(this).attr("data-popup-id")}`);
        $popup.addClass("is-active");
      });
      
      $(".js-close-popup-slide").click(function (e) {
        e.preventDefault();
        let $popup = $(`#${$(this).attr("data-popup-id")}`);
        $popup.removeClass("is-active");
    });        
    */

  // Open popup
  const btnPopupSlide = document.querySelectorAll(".c-btn-popup-slide");
  btnPopupSlide.forEach((boton) => {
    boton.addEventListener("click", () => {
      let popupId = boton.getAttribute("data-popup-id");
      let popup = document.getElementById(popupId);
      Util.addClass(popup, "is-active");
      return false;
    });
  });

  // close popup
  const btnPopupSlideClose = document.querySelectorAll(
    ".c-btn-popup-slide-close"
  );
  btnPopupSlideClose.forEach((boton) => {
    boton.addEventListener("click", () => {
      let popupId = boton.getAttribute("data-popup-id");
      let popup = document.getElementById(popupId);
      Util.removeClass(popup, "is-active");
      return false;
    });
  });
}

// END OTHER FUNCTIONS

// MAIN JS FUNCTIONS
function CopyLink() {
  document.getElementById("txt-direccion-envio").select();
  document.execCommand("copy");
}

function MainInit() {
  M.updateTextFields();

  let Modalelems = Array.from(document.querySelectorAll(".modal"));

  Modalelems.forEach((item, index) => {
    let option = {};
    M.Modal.init(item, option);
  });

  let showOnboarding = document.querySelector(".c-show-onboarding");
  if (showOnboarding) {
    let elem = document.querySelector("#modalOnboardingApp");
    let modalWelcome = M.Modal.init(elem, {});
    if (modalWelcome) {
      modalWelcome.open();
    }
  }

  // Tabs
  let el = document.querySelector(".tabs");
  let instance_tab = M.Tabs.init(el);

  // Sidenav
  let elems_sidenav = document.querySelectorAll(".sidenav");
  let instances_sidenav = M.Sidenav.init(elems_sidenav);

  // Select
  let elems_select = document.querySelectorAll(".c-select select");
  let instances_select = M.FormSelect.init(elems_select);

  // Autocomplete
  let elems_autocomplete = document.querySelectorAll(".autocomplete");
  let inputSearchPoints_autocomplete = document.querySelectorAll(
    "#c-input-search-points"
  );
  let inputSearchCity_autocomplete = document.querySelectorAll(
    ".c-input-search-city"
  );
  let inputSearchPointScharff_autocomplete = document.querySelectorAll(
    ".c-input-search-point-scharff"
  );

  const formSearchPoints = document.querySelector("#c-form-search-points");

  let options = {
    data: {},
  };

  let options_point_scharff = {
    data: {},
  };

  let instances_autocomplete = M.Autocomplete.init(elems_autocomplete, options);
  let instances_inputSearchPoints = M.Autocomplete.init(
    inputSearchPoints_autocomplete,
    options
  );
  let instances_inputSearchCity = M.Autocomplete.init(
    inputSearchCity_autocomplete,
    options
  );
  let instances_inputSearchPointScharff = M.Autocomplete.init(
    inputSearchPointScharff_autocomplete,
    options_point_scharff
  );

  if (inputSearchPoints_autocomplete.length !== 0) {
    Other.searchPointScharff();
  }

  window.addEventListener(
    "resize",
    function (event) {
      if (window.matchMedia("(min-width: 824px)").matches) {
        let modalCalculatorProgrammed =
          document.querySelector("#modalCalculator");
        if (modalCalculatorProgrammed) {
          let instance_calculator_programmed = M.Modal.getInstance(
            modalCalculatorProgrammed
          );
          instance_calculator_programmed.close();
        }
      }
    },
    true
  );

  let ClickCopyLink = document.getElementById("CopyLink");

  if (ClickCopyLink) {
    ClickCopyLink.addEventListener("click", CopyLink);
    CopyLink();
  }

  /*
    |--------------------------------------------------------------------------
    | Init javascript
    |--------------------------------------------------------------------------
    |
    */

  executeOtherInit();
  executeOtherPopupSlide();
}

function initMaterilizeComponents() {
  // Select
  let elems_select = document.querySelectorAll(".c-select select");
  let instances_select = M.FormSelect.init(elems_select);

  // Sidenav
  let elems_sidenav = document.querySelectorAll(".sidenav");
  let instances_sidenav = M.Sidenav.init(elems_sidenav);
}

function initCulqiJS() {
  setTimeout(() => {
    Culqi.publicKey = process.env.VUE_APP_CULQI;
    Culqi.init();
  }, 500);
}

function initModalStartJS() {
  /*Modal Start*/
  let modalStart = document.querySelector("#modalOnboardingAppStart");
  let instance3 = M.Modal.getInstance(modalStart);
  if (instance3) instance3.open();

  /*End Modal Start*/
}

// END MAIN JS FUNCTIONS

class ReMapped {
  init() {
    MainInit();
  }

  initGoogleMap() {
    executeOtherInitMap();
  }

  initMapSaioView() {
    executeOtherInitMapSaioView();
  }

  initMaterilize() {
    initMaterilizeComponents();
  }

  initCulqi() {
    initCulqiJS();
  }

  initPopupSlide() {
    executeOtherPopupSlide();
  }

  initModalStart() {
    initModalStartJS();
  }
}
export default ReMapped;
