/* eslint-disable no-var */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
class MapComponent {
  constructor() {
    this.map = null;
    this.markerLabels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    this.startPosition = { lat: -12.107006, lng: -76.985821 };
    this.directionsDisplay = null;
    this.directionsRenderer = null;
    this.geocoder = null;
    this.markers = [];
    this.markerIndex = 0;
    this.waypointMarkers = [];
    this.waypointRequestCounter = 0;
    this.currentInfowindow = null;
    this.scharffPointMarkers = [];
    this.scharffExpressCoverage = [
      [
        { lng: -77.019825, lat: -12.073147 },
        { lng: -77.012873, lat: -12.099249 },
        { lng: -77.002159, lat: -12.097958 },
        { lng: -77.001157, lat: -12.104884 },
        { lng: -77.00022, lat: -12.111727 },
        { lng: -77.000128, lat: -12.112427 },
        { lng: -76.99995, lat: -12.113125 },
        { lng: -76.999566, lat: -12.114558 },
        { lng: -76.998421, lat: -12.118748 },
        { lng: -76.998264, lat: -12.11984 },
        { lng: -76.998276, lat: -12.120848 },
        { lng: -76.998384, lat: -12.121859 },
        { lng: -76.998499, lat: -12.12238 },
        { lng: -76.999395, lat: -12.124465 },
        { lng: -77.000267, lat: -12.126744 },
        { lng: -77.000531, lat: -12.127446 },
        { lng: -77.001099, lat: -12.128848 },
        { lng: -76.999645, lat: -12.130983 },
        { lng: -76.998253, lat: -12.132958 },
        { lng: -76.998124, lat: -12.133272 },
        { lng: -76.997572, lat: -12.132882 },
        { lng: -76.997816, lat: -12.132539 },
        { lng: -76.998291, lat: -12.132838 },
        { lng: -76.999691, lat: -12.130842 },
        { lng: -77.001063, lat: -12.128857 },
        { lng: -77.00019, lat: -12.126673 },
        { lng: -76.999353, lat: -12.124501 },
        { lng: -76.998434, lat: -12.122344 },
        { lng: -76.998313, lat: -12.121889 },
        { lng: -76.998183, lat: -12.11985 },
        { lng: -76.998404, lat: -12.118624 },
        { lng: -76.999144, lat: -12.115811 },
        { lng: -76.999681, lat: -12.113779 },
        { lng: -76.99995, lat: -12.112799 },
        { lng: -77.000064, lat: -12.112409 },
        { lng: -77.000161, lat: -12.111744 },
        { lng: -77.002071, lat: -12.097929 },
        { lng: -76.996615, lat: -12.097368 },
        { lng: -76.990858, lat: -12.096689 },
        { lng: -76.979851, lat: -12.095409 },
        { lng: -76.973961, lat: -12.094827 },
        { lng: -76.972079, lat: -12.102596 },
        { lng: -76.970842, lat: -12.10306 },
        { lng: -76.969902, lat: -12.100621 },
        { lng: -76.971332, lat: -12.100074 },
        { lng: -76.972501, lat: -12.099645 },
        { lng: -76.973639, lat: -12.094761 },
        { lng: -76.971516, lat: -12.094479 },
        { lng: -76.968844, lat: -12.094129 },
        { lng: -76.966818, lat: -12.093006 },
        { lng: -76.964645, lat: -12.09141 },
        { lng: -76.963097, lat: -12.089809 },
        { lng: -76.960258, lat: -12.082419 },
        { lng: -76.957174, lat: -12.078243 },
        { lng: -76.954926, lat: -12.07984 },
        { lng: -76.957375, lat: -12.08214 },
        { lng: -76.957977, lat: -12.08428 },
        { lng: -76.957481, lat: -12.085999 },
        { lng: -76.95887, lat: -12.08715 },
        { lng: -76.959038, lat: -12.08882 },
        { lng: -76.958061, lat: -12.08927 },
        { lng: -76.960526, lat: -12.092859 },
        { lng: -76.958221, lat: -12.092959 },
        { lng: -76.95694, lat: -12.093019 },
        { lng: -76.95623, lat: -12.092549 },
        { lng: -76.953606, lat: -12.09196 },
        { lng: -76.953461, lat: -12.092509 },
        { lng: -76.95327, lat: -12.09392 },
        { lng: -76.952065, lat: -12.0969 },
        { lng: -76.952927, lat: -12.0977 },
        { lng: -76.95256, lat: -12.10007 },
        { lng: -76.949265, lat: -12.10524 },
        { lng: -76.947731, lat: -12.10728 },
        { lng: -76.945412, lat: -12.108069 },
        { lng: -76.945259, lat: -12.11004 },
        { lng: -76.944168, lat: -12.1131 },
        { lng: -76.941887, lat: -12.11308 },
        { lng: -76.940857, lat: -12.114599 },
        { lng: -76.94165, lat: -12.11619 },
        { lng: -76.942902, lat: -12.11737 },
        { lng: -76.942345, lat: -12.11761 },
        { lng: -76.938019, lat: -12.11846 },
        { lng: -76.934715, lat: -12.118609 },
        { lng: -76.932358, lat: -12.11955 },
        { lng: -76.931076, lat: -12.11949 },
        { lng: -76.930428, lat: -12.117129 },
        { lng: -76.931396, lat: -12.11469 },
        { lng: -76.933769, lat: -12.112889 },
        { lng: -76.935226, lat: -12.110579 },
        { lng: -76.933472, lat: -12.10895 },
        { lng: -76.933495, lat: -12.10664 },
        { lng: -76.935242, lat: -12.10442 },
        { lng: -76.932831, lat: -12.103769 },
        { lng: -76.932716, lat: -12.10296 },
        { lng: -76.933907, lat: -12.101649 },
        { lng: -76.936607, lat: -12.1015 },
        { lng: -76.937592, lat: -12.100229 },
        { lng: -76.937027, lat: -12.09962 },
        { lng: -76.937012, lat: -12.09908 },
        { lng: -76.939316, lat: -12.098829 },
        { lng: -76.940819, lat: -12.09789 },
        { lng: -76.939781, lat: -12.09664 },
        { lng: -76.940941, lat: -12.09554 },
        { lng: -76.942909, lat: -12.09481 },
        { lng: -76.943504, lat: -12.09353 },
        { lng: -76.942726, lat: -12.09291 },
        { lng: -76.943146, lat: -12.09199 },
        { lng: -76.945358, lat: -12.09208 },
        { lng: -76.944077, lat: -12.089109 },
        { lng: -76.938515, lat: -12.086289 },
        { lng: -76.937981, lat: -12.08871 },
        { lng: -76.936806, lat: -12.09139 },
        { lng: -76.936852, lat: -12.09306 },
        { lng: -76.935806, lat: -12.09474 },
        { lng: -76.932266, lat: -12.09451 },
        { lng: -76.933617, lat: -12.091789 },
        { lng: -76.933037, lat: -12.08904 },
        { lng: -76.930351, lat: -12.089499 },
        { lng: -76.927956, lat: -12.08799 },
        { lng: -76.926018, lat: -12.08982 },
        { lng: -76.927299, lat: -12.096149 },
        { lng: -76.927849, lat: -12.097569 },
        { lng: -76.927795, lat: -12.098829 },
        { lng: -76.927071, lat: -12.09882 },
        { lng: -76.923378, lat: -12.097569 },
        { lng: -76.922134, lat: -12.09845 },
        { lng: -76.920807, lat: -12.098579 },
        { lng: -76.921349, lat: -12.095989 },
        { lng: -76.923035, lat: -12.09234 },
        { lng: -76.921127, lat: -12.08859 },
        { lng: -76.919655, lat: -12.085239 },
        { lng: -76.915329, lat: -12.085999 },
        { lng: -76.910896, lat: -12.08644 },
        { lng: -76.907776, lat: -12.08745 },
        { lng: -76.906868, lat: -12.0904 },
        { lng: -76.903877, lat: -12.09203 },
        { lng: -76.903236, lat: -12.0933 },
        { lng: -76.901291, lat: -12.09499 },
        { lng: -76.897995, lat: -12.095659 },
        { lng: -76.897278, lat: -12.094319 },
        { lng: -76.897858, lat: -12.0928 },
        { lng: -76.898438, lat: -12.08951 },
        { lng: -76.895737, lat: -12.08847 },
        { lng: -76.893539, lat: -12.08748 },
        { lng: -76.890732, lat: -12.088329 },
        { lng: -76.887901, lat: -12.089089 },
        { lng: -76.886536, lat: -12.08741 },
        { lng: -76.88765, lat: -12.08531 },
        { lng: -76.889351, lat: -12.08527 },
        { lng: -76.891899, lat: -12.08344 },
        { lng: -76.891777, lat: -12.079289 },
        { lng: -76.894081, lat: -12.07918 },
        { lng: -76.895157, lat: -12.079929 },
        { lng: -76.897057, lat: -12.07972 },
        { lng: -76.899376, lat: -12.078099 },
        { lng: -76.902641, lat: -12.07523 },
        { lng: -76.903816, lat: -12.07422 },
        { lng: -76.903801, lat: -12.07321 },
        { lng: -76.902847, lat: -12.07167 },
        { lng: -76.90284, lat: -12.06841 },
        { lng: -76.903969, lat: -12.06889 },
        { lng: -76.90493, lat: -12.07139 },
        { lng: -76.9076, lat: -12.07226 },
        { lng: -76.907776, lat: -12.06975 },
        { lng: -76.910446, lat: -12.06868 },
        { lng: -76.910332, lat: -12.0671 },
        { lng: -76.909065, lat: -12.06555 },
        { lng: -76.907997, lat: -12.06356 },
        { lng: -76.908737, lat: -12.06356 },
        { lng: -76.911171, lat: -12.06533 },
        { lng: -76.912308, lat: -12.065289 },
        { lng: -76.91256, lat: -12.0657 },
        { lng: -76.912186, lat: -12.066299 },
        { lng: -76.91275, lat: -12.06753 },
        { lng: -76.91349, lat: -12.06641 },
        { lng: -76.914871, lat: -12.06613 },
        { lng: -76.91539, lat: -12.06781 },
        { lng: -76.913376, lat: -12.068649 },
        { lng: -76.91346, lat: -12.06989 },
        { lng: -76.916176, lat: -12.07312 },
        { lng: -76.918007, lat: -12.072599 },
        { lng: -76.918686, lat: -12.07314 },
        { lng: -76.918777, lat: -12.0748 },
        { lng: -76.919914, lat: -12.07632 },
        { lng: -76.922195, lat: -12.076449 },
        { lng: -76.923401, lat: -12.07564 },
        { lng: -76.924171, lat: -12.071159 },
        { lng: -76.925186, lat: -12.070889 },
        { lng: -76.928459, lat: -12.07165 },
        { lng: -76.931549, lat: -12.074409 },
        { lng: -76.932297, lat: -12.073199 },
        { lng: -76.935287, lat: -12.07344 },
        { lng: -76.933456, lat: -12.0715 },
        { lng: -76.934311, lat: -12.06918 },
        { lng: -76.933205, lat: -12.06719 },
        { lng: -76.934601, lat: -12.066999 },
        { lng: -76.935049, lat: -12.063574 },
        { lng: -76.934128, lat: -12.06339 },
        { lng: -76.933601, lat: -12.06211 },
        { lng: -76.934288, lat: -12.05979 },
        { lng: -76.934998, lat: -12.058519 },
        { lng: -76.934952, lat: -12.05477 },
        { lng: -76.935959, lat: -12.05348 },
        { lng: -76.937477, lat: -12.0527 },
        { lng: -76.937477, lat: -12.050759 },
        { lng: -76.936447, lat: -12.04867 },
        { lng: -76.935153, lat: -12.047295 },
        { lng: -76.935099, lat: -12.04718 },
        { lng: -76.935078, lat: -12.047148 },
        { lng: -76.935051, lat: -12.0471 },
        { lng: -76.933266, lat: -12.046679 },
        { lng: -76.932007, lat: -12.04644 },
        { lng: -76.92878, lat: -12.045159 },
        { lng: -76.92717, lat: -12.04443 },
        { lng: -76.92749, lat: -12.04375 },
        { lng: -76.925529, lat: -12.043139 },
        { lng: -76.924141, lat: -12.042649 },
        { lng: -76.9226, lat: -12.041699 },
        { lng: -76.924194, lat: -12.037829 },
        { lng: -76.922089, lat: -12.03734 },
        { lng: -76.919975, lat: -12.03617 },
        { lng: -76.92144, lat: -12.03347 },
        { lng: -76.921867, lat: -12.03243 },
        { lng: -76.922195, lat: -12.03103 },
        { lng: -76.92321, lat: -12.029759 },
        { lng: -76.923254, lat: -12.028072 },
        { lng: -76.912791, lat: -12.025064 },
        { lng: -76.913366, lat: -12.023034 },
        { lng: -76.924507, lat: -12.02485 },
        { lng: -76.9291, lat: -12.02547 },
        { lng: -76.932625, lat: -12.0261 },
        { lng: -76.943855, lat: -12.02765 },
        { lng: -76.943865, lat: -12.027673 },
        { lng: -76.943954, lat: -12.027679 },
        { lng: -76.9608, lat: -12.030169 },
        { lng: -76.964775, lat: -12.03263 },
        { lng: -76.972488, lat: -12.03861 },
        { lng: -76.975365, lat: -12.040339 },
        { lng: -76.982246, lat: -12.040239 },
        { lng: -76.988655, lat: -12.039559 },
        { lng: -76.98877, lat: -12.039949 },
        { lng: -76.986069, lat: -12.043469 },
        { lng: -76.976906, lat: -12.051499 },
        { lng: -76.973532, lat: -12.058475 },
        { lng: -76.976265, lat: -12.059672 },
        { lng: -76.977678, lat: -12.060285 },
        { lng: -76.978469, lat: -12.060574 },
        { lng: -76.978597, lat: -12.060149 },
        { lng: -76.978737, lat: -12.059879 },
        { lng: -76.979188, lat: -12.059682 },
        { lng: -76.979881, lat: -12.05913 },
        { lng: -76.979947, lat: -12.058485 },
        { lng: -76.980215, lat: -12.05848 },
        { lng: -76.980155, lat: -12.059186 },
        { lng: -76.979287, lat: -12.059919 },
        { lng: -76.978842, lat: -12.060068 },
        { lng: -76.978652, lat: -12.060611 },
        { lng: -76.978762, lat: -12.060715 },
        { lng: -76.983519, lat: -12.062782 },
        { lng: -76.986887, lat: -12.063969 },
        { lng: -76.986885, lat: -12.06396 },
        { lng: -76.990067, lat: -12.064119 },
        { lng: -76.998116, lat: -12.06347 },
        { lng: -76.99781, lat: -12.06119 },
        { lng: -76.99839, lat: -12.06115 },
        { lng: -76.999428, lat: -12.06102 },
        { lng: -76.999886, lat: -12.06145 },
        { lng: -77.001015, lat: -12.061669 },
        { lng: -77.00174, lat: -12.061729 },
        { lng: -77.001991, lat: -12.06157 },
        { lng: -77.001972, lat: -12.06169 },
        { lng: -77.002045, lat: -12.06179 },
        { lng: -77.002914, lat: -12.06153 },
        { lng: -77.003131, lat: -12.062383 },
        { lng: -77.001266, lat: -12.063379 },
        { lng: -77.000465, lat: -12.06339 },
        { lng: -76.996552, lat: -12.06824 },
        { lng: -77.002401, lat: -12.071385 },
        { lng: -77.019825, lat: -12.073147 },
      ],
      [
        { lng: -77.0854, lat: -11.975824 },
        { lng: -77.082681, lat: -11.977028 },
        { lng: -77.076009, lat: -11.978427 },
        { lng: -77.07344, lat: -11.978875 },
        { lng: -77.065802, lat: -11.977734 },
        { lng: -77.059263, lat: -11.976307 },
        { lng: -77.057358, lat: -11.97265 },
        { lng: -77.057297, lat: -11.968539 },
        { lng: -77.055305, lat: -11.965219 },
        { lng: -77.052788, lat: -11.95757 },
        { lng: -77.050705, lat: -11.95162 },
        { lng: -77.049126, lat: -11.950069 },
        { lng: -77.049286, lat: -11.94848 },
        { lng: -77.050635, lat: -11.944417 },
        { lng: -77.050353, lat: -11.939557 },
        { lng: -77.049224, lat: -11.93726 },
        { lng: -77.047439, lat: -11.934663 },
        { lng: -77.04459, lat: -11.931209 },
        { lng: -77.043671, lat: -11.92972 },
        { lng: -77.042847, lat: -11.927569 },
        { lng: -77.041145, lat: -11.91714 },
        { lng: -77.039919, lat: -11.913518 },
        { lng: -77.037349, lat: -11.909243 },
        { lng: -77.034445, lat: -11.908347 },
        { lng: -77.033291, lat: -11.907811 },
        { lng: -77.032332, lat: -11.906913 },
        { lng: -77.030861, lat: -11.903499 },
        { lng: -77.030756, lat: -11.902525 },
        { lng: -77.030792, lat: -11.900259 },
        { lng: -77.03111, lat: -11.899463 },
        { lng: -77.039925, lat: -11.891852 },
        { lng: -77.042966, lat: -11.89068 },
        { lng: -77.045141, lat: -11.891767 },
        { lng: -77.04791, lat: -11.896547 },
        { lng: -77.055659, lat: -11.909766 },
        { lng: -77.056305, lat: -11.91057 },
        { lng: -77.06115, lat: -11.919399 },
        { lng: -77.063556, lat: -11.922657 },
        { lng: -77.066517, lat: -11.927821 },
        { lng: -77.069, lat: -11.930079 },
        { lng: -77.072556, lat: -11.93049 },
        { lng: -77.072868, lat: -11.930593 },
        { lng: -77.073255, lat: -11.930677 },
        { lng: -77.077766, lat: -11.93131 },
        { lng: -77.079979, lat: -11.93345 },
        { lng: -77.081245, lat: -11.93456 },
        { lng: -77.082642, lat: -11.93769 },
        { lng: -77.078705, lat: -11.93901 },
        { lng: -77.078358, lat: -11.940922 },
        { lng: -77.07795, lat: -11.943009 },
        { lng: -77.078156, lat: -11.943069 },
        { lng: -77.078285, lat: -11.94356 },
        { lng: -77.078247, lat: -11.94382 },
        { lng: -77.079071, lat: -11.944409 },
        { lng: -77.078979, lat: -11.94498 },
        { lng: -77.078865, lat: -11.945459 },
        { lng: -77.078819, lat: -11.94558 },
        { lng: -77.079239, lat: -11.94602 },
        { lng: -77.080215, lat: -11.94473 },
        { lng: -77.08165, lat: -11.94374 },
        { lng: -77.082214, lat: -11.944779 },
        { lng: -77.082878, lat: -11.94499 },
        { lng: -77.08326, lat: -11.94529 },
        { lng: -77.084122, lat: -11.94497 },
        { lng: -77.083817, lat: -11.94376 },
        { lng: -77.085327, lat: -11.94311 },
        { lng: -77.085907, lat: -11.945009 },
        { lng: -77.087616, lat: -11.94416 },
        { lng: -77.088699, lat: -11.94294 },
        { lng: -77.089867, lat: -11.94395 },
        { lng: -77.088376, lat: -11.946361 },
        { lng: -77.086128, lat: -11.947289 },
        { lng: -77.084038, lat: -11.962049 },
        { lng: -77.083481, lat: -11.96536 },
        { lng: -77.083611, lat: -11.96799 },
        { lng: -77.084785, lat: -11.97028 },
        { lng: -77.08811, lat: -11.973998 },
        { lng: -77.0854, lat: -11.975824 },
      ],
      [
        { lng: -77.116761, lat: -12.000992 },
        { lng: -77.108055, lat: -12.018499 },
        { lng: -77.109138, lat: -12.01908 },
        { lng: -77.108765, lat: -12.01978 },
        { lng: -77.107697, lat: -12.019219 },
        { lng: -77.106827, lat: -12.02098 },
        { lng: -77.09948, lat: -12.03609 },
        { lng: -77.099438, lat: -12.036885 },
        { lng: -77.098804, lat: -12.036862 },
        { lng: -77.098831, lat: -12.03615 },
        { lng: -77.097099, lat: -12.03594 },
        { lng: -77.091972, lat: -12.02498 },
        { lng: -77.089408, lat: -12.0195 },
        { lng: -77.089088, lat: -12.018815 },
        { lng: -77.08518, lat: -12.020793 },
        { lng: -77.084748, lat: -12.01963 },
        { lng: -77.088768, lat: -12.01813 },
        { lng: -77.088127, lat: -12.01676 },
        { lng: -77.086845, lat: -12.01402 },
        { lng: -77.086937, lat: -12.01304 },
        { lng: -77.087073, lat: -12.012625 },
        { lng: -77.087148, lat: -12.012562 },
        { lng: -77.087138, lat: -12.01252 },
        { lng: -77.087158, lat: -12.01244 },
        { lng: -77.082619, lat: -12.01324 },
        { lng: -77.081248, lat: -12.013171 },
        { lng: -77.081044, lat: -12.01315 },
        { lng: -77.081054, lat: -12.013308 },
        { lng: -77.081054, lat: -12.013507 },
        { lng: -77.081022, lat: -12.013685 },
        { lng: -77.081012, lat: -12.013948 },
        { lng: -77.080926, lat: -12.014399 },
        { lng: -77.080582, lat: -12.018789 },
        { lng: -77.07782, lat: -12.01989 },
        { lng: -77.07716, lat: -12.021818 },
        { lng: -77.079473, lat: -12.027536 },
        { lng: -77.080346, lat: -12.029598 },
        { lng: -77.080839, lat: -12.030714 },
        { lng: -77.085353, lat: -12.030343 },
        { lng: -77.08561, lat: -12.030947 },
        { lng: -77.08187, lat: -12.031498 },
        { lng: -77.081981, lat: -12.03186 },
        { lng: -77.08091, lat: -12.031996 },
        { lng: -77.080867, lat: -12.031571 },
        { lng: -77.079949, lat: -12.03168 },
        { lng: -77.078506, lat: -12.03187 },
        { lng: -77.078527, lat: -12.031987 },
        { lng: -77.077578, lat: -12.032147 },
        { lng: -77.077538, lat: -12.032 },
        { lng: -77.068228, lat: -12.032246 },
        { lng: -77.066212, lat: -12.032475 },
        { lng: -77.066216, lat: -12.03204 },
        { lng: -77.066095, lat: -12.031488 },
        { lng: -77.06823, lat: -12.031443 },
        { lng: -77.077205, lat: -12.031138 },
        { lng: -77.075584, lat: -12.02633 },
        { lng: -77.069817, lat: -12.02849 },
        { lng: -77.067612, lat: -12.028 },
        { lng: -77.066429, lat: -12.02845 },
        { lng: -77.065155, lat: -12.029449 },
        { lng: -77.060707, lat: -12.03014 },
        { lng: -77.059761, lat: -12.03012 },
        { lng: -77.058556, lat: -12.0303 },
        { lng: -77.056786, lat: -12.030869 },
        { lng: -77.050774, lat: -12.027113 },
        { lng: -77.048165, lat: -12.024562 },
        { lng: -77.049988, lat: -12.023069 },
        { lng: -77.050438, lat: -12.02216 },
        { lng: -77.053581, lat: -12.00747 },
        { lng: -77.053589, lat: -12.007368 },
        { lng: -77.053589, lat: -12.007294 },
        { lng: -77.055, lat: -11.99861 },
        { lng: -77.054848, lat: -11.997109 },
        { lng: -77.054985, lat: -11.996039 },
        { lng: -77.056549, lat: -11.993649 },
        { lng: -77.056938, lat: -11.99222 },
        { lng: -77.056671, lat: -11.98895 },
        { lng: -77.059601, lat: -11.976959 },
        { lng: -77.059603, lat: -11.976959 },
        { lng: -77.057358, lat: -11.97265 },
        { lng: -77.057297, lat: -11.968539 },
        { lng: -77.055305, lat: -11.965219 },
        { lng: -77.052788, lat: -11.95757 },
        { lng: -77.050705, lat: -11.95162 },
        { lng: -77.049126, lat: -11.950069 },
        { lng: -77.049286, lat: -11.94848 },
        { lng: -77.049669, lat: -11.946881 },
        { lng: -77.068577, lat: -11.936877 },
        { lng: -77.076988, lat: -11.937899 },
        { lng: -77.078733, lat: -11.938739 },
        { lng: -77.078376, lat: -11.94095 },
        { lng: -77.07795, lat: -11.943009 },
        { lng: -77.078156, lat: -11.943069 },
        { lng: -77.078285, lat: -11.94356 },
        { lng: -77.078247, lat: -11.94382 },
        { lng: -77.079071, lat: -11.944409 },
        { lng: -77.078979, lat: -11.94498 },
        { lng: -77.078865, lat: -11.945459 },
        { lng: -77.078819, lat: -11.94558 },
        { lng: -77.079239, lat: -11.94602 },
        { lng: -77.080215, lat: -11.94473 },
        { lng: -77.08165, lat: -11.94374 },
        { lng: -77.082214, lat: -11.944779 },
        { lng: -77.082878, lat: -11.94499 },
        { lng: -77.08326, lat: -11.94529 },
        { lng: -77.084122, lat: -11.94497 },
        { lng: -77.083817, lat: -11.94376 },
        { lng: -77.085327, lat: -11.94311 },
        { lng: -77.085907, lat: -11.945009 },
        { lng: -77.087616, lat: -11.94416 },
        { lng: -77.088699, lat: -11.94294 },
        { lng: -77.089867, lat: -11.94395 },
        { lng: -77.091899, lat: -11.945482 },
        { lng: -77.088875, lat: -11.948716 },
        { lng: -77.091027, lat: -11.950764 },
        { lng: -77.089303, lat: -11.952932 },
        { lng: -77.089052, lat: -11.953733 },
        { lng: -77.089265, lat: -11.954321 },
        { lng: -77.089735, lat: -11.954952 },
        { lng: -77.092265, lat: -11.956852 },
        { lng: -77.09059, lat: -11.959193 },
        { lng: -77.094441, lat: -11.96226 },
        { lng: -77.092541, lat: -11.964551 },
        { lng: -77.095071, lat: -11.966593 },
        { lng: -77.092001, lat: -11.969716 },
        { lng: -77.094479, lat: -11.9716 },
        { lng: -77.095197, lat: -11.972613 },
        { lng: -77.100518, lat: -11.978099 },
        { lng: -77.103291, lat: -11.980678 },
        { lng: -77.103787, lat: -11.983751 },
        { lng: -77.104739, lat: -11.984338 },
        { lng: -77.105545, lat: -11.985388 },
        { lng: -77.107357, lat: -11.988848 },
        { lng: -77.109147, lat: -11.990892 },
        { lng: -77.112621, lat: -11.994764 },
        { lng: -77.112954, lat: -11.997044 },
        { lng: -77.116761, lat: -12.000992 },
      ],
      [
        { lng: -77.105985, lat: -11.989024 },
        { lng: -77.106332, lat: -11.989361 },
        { lng: -77.106393, lat: -11.989134 },
        { lng: -77.105985, lat: -11.989024 },
      ],
      [
        { lng: -77.10434, lat: -11.988935 },
        { lng: -77.103946, lat: -11.988447 },
        { lng: -77.103764, lat: -11.98898 },
        { lng: -77.10434, lat: -11.988935 },
      ],
      [
        { lng: -77.071395, lat: -12.003311 },
        { lng: -77.072883, lat: -12.0053 },
        { lng: -77.075836, lat: -12.005191 },
        { lng: -77.076324, lat: -12.002681 },
        { lng: -77.079315, lat: -12.00287 },
        { lng: -77.080764, lat: -12.00462 },
        { lng: -77.081545, lat: -12.006146 },
        { lng: -77.082916, lat: -11.998481 },
        { lng: -77.078643, lat: -11.997781 },
        { lng: -77.076232, lat: -11.999591 },
        { lng: -77.074363, lat: -11.999671 },
        { lng: -77.073692, lat: -11.99871 },
        { lng: -77.073127, lat: -11.997471 },
        { lng: -77.073501, lat: -11.996581 },
        { lng: -77.072616, lat: -11.99585 },
        { lng: -77.067512, lat: -11.994971 },
        { lng: -77.065315, lat: -11.99768 },
        { lng: -77.067016, lat: -12.002761 },
        { lng: -77.071395, lat: -12.003311 },
      ],
      [
        { lng: -77.072952, lat: -12.02317 },
        { lng: -77.074165, lat: -12.022621 },
        { lng: -77.07461, lat: -12.020001 },
        { lng: -77.07445, lat: -12.019947 },
        { lng: -77.073742, lat: -12.019606 },
        { lng: -77.073915, lat: -12.019293 },
        { lng: -77.073102, lat: -12.018855 },
        { lng: -77.072623, lat: -12.018494 },
        { lng: -77.071859, lat: -12.017457 },
        { lng: -77.071158, lat: -12.016484 },
        { lng: -77.071514, lat: -12.016207 },
        { lng: -77.070778, lat: -12.015128 },
        { lng: -77.070251, lat: -12.014516 },
        { lng: -77.069173, lat: -12.0153 },
        { lng: -77.068326, lat: -12.014057 },
        { lng: -77.06918, lat: -12.013651 },
        { lng: -77.068967, lat: -12.012956 },
        { lng: -77.068049, lat: -12.012527 },
        { lng: -77.068982, lat: -12.010482 },
        { lng: -77.064987, lat: -12.009081 },
        { lng: -77.061683, lat: -12.008404 },
        { lng: -77.061676, lat: -12.00845 },
        { lng: -77.061634, lat: -12.008444 },
        { lng: -77.060096, lat: -12.018671 },
        { lng: -77.062667, lat: -12.01907 },
        { lng: -77.062072, lat: -12.024781 },
        { lng: -77.064056, lat: -12.025 },
        { lng: -77.066467, lat: -12.023881 },
        { lng: -77.068054, lat: -12.02603 },
        { lng: -77.068336, lat: -12.027381 },
        { lng: -77.070106, lat: -12.027981 },
        { lng: -77.071083, lat: -12.02753 },
        { lng: -77.072952, lat: -12.02317 },
      ],
      [
        { lng: -77.015533, lat: -12.134495 },
        { lng: -76.976395, lat: -12.133907 },
        { lng: -76.976995, lat: -12.117376 },
        { lng: -76.969614, lat: -12.113096 },
        { lng: -76.968241, lat: -12.10034 },
        { lng: -76.969442, lat: -12.083051 },
        { lng: -76.978369, lat: -12.060809 },
        { lng: -76.984205, lat: -12.062907 },
        { lng: -76.98678, lat: -12.063999 },
        { lng: -76.997852, lat: -12.063327 },
        { lng: -76.997595, lat: -12.061145 },
        { lng: -77.00017, lat: -12.061313 },
        { lng: -77.002831, lat: -12.061397 },
        { lng: -77.003088, lat: -12.062404 },
        { lng: -77.001286, lat: -12.063243 },
        { lng: -77.000427, lat: -12.063411 },
        { lng: -76.996737, lat: -12.067944 },
        { lng: -77.002916, lat: -12.071721 },
        { lng: -77.005577, lat: -12.068363 },
        { lng: -77.001715, lat: -12.063831 },
        { lng: -77.004032, lat: -12.062404 },
        { lng: -77.006779, lat: -12.065593 },
        { lng: -77.009354, lat: -12.065845 },
        { lng: -77.010212, lat: -12.059466 },
        { lng: -77.01313, lat: -12.057619 },
        { lng: -77.013076, lat: -12.056951 },
        { lng: -77.014275, lat: -12.05589 },
        { lng: -77.01441, lat: -12.056044 },
        { lng: -77.013428, lat: -12.05487 },
        { lng: -77.013786, lat: -12.05451 },
        { lng: -77.016037, lat: -12.05514 },
        { lng: -77.016617, lat: -12.05057 },
        { lng: -77.017548, lat: -12.047 },
        { lng: -77.020142, lat: -12.04782 },
        { lng: -77.026085, lat: -12.046619 },
        { lng: -77.026391, lat: -12.04633 },
        { lng: -77.026863, lat: -12.04612 },
        { lng: -77.027292, lat: -12.045868 },
        { lng: -77.027807, lat: -12.045616 },
        { lng: -77.02858, lat: -12.045071 },
        { lng: -77.030168, lat: -12.044273 },
        { lng: -77.031713, lat: -12.043308 },
        { lng: -77.033472, lat: -12.04251 },
        { lng: -77.034592, lat: -12.041964 },
        { lng: -77.034378, lat: -12.04163 },
        { lng: -77.039398, lat: -12.039149 },
        { lng: -77.04267, lat: -12.039305 },
        { lng: -77.043537, lat: -12.039978 },
        { lng: -77.043521, lat: -12.04012 },
        { lng: -77.046362, lat: -12.04042 },
        { lng: -77.047777, lat: -12.040564 },
        { lng: -77.048485, lat: -12.040637 },
        { lng: -77.048691, lat: -12.039123 },
        { lng: -77.049119, lat: -12.039137 },
        { lng: -77.048844, lat: -12.040673 },
        { lng: -77.049171, lat: -12.040709 },
        { lng: -77.05142, lat: -12.040344 },
        { lng: -77.051015, lat: -12.038569 },
        { lng: -77.053552, lat: -12.038728 },
        { lng: -77.054726, lat: -12.03864 },
        { lng: -77.056847, lat: -12.03832 },
        { lng: -77.056908, lat: -12.03955 },
        { lng: -77.066088, lat: -12.038243 },
        { lng: -77.065494, lat: -12.037831 },
        { lng: -77.06582, lat: -12.037589 },
        { lng: -77.066356, lat: -12.037562 },
        { lng: -77.066611, lat: -12.03818 },
        { lng: -77.067134, lat: -12.038117 },
        { lng: -77.068181, lat: -12.037992 },
        { lng: -77.070274, lat: -12.03774 },
        { lng: -77.077279, lat: -12.038514 },
        { lng: -77.077476, lat: -12.035751 },
        { lng: -77.077469, lat: -12.035749 },
        { lng: -77.078285, lat: -12.03475 },
        { lng: -77.078102, lat: -12.034075 },
        { lng: -77.078912, lat: -12.034088 },
        { lng: -77.079283, lat: -12.036113 },
        { lng: -77.08831, lat: -12.03793 },
        { lng: -77.087803, lat: -12.039929 },
        { lng: -77.087653, lat: -12.040328 },
        { lng: -77.087524, lat: -12.040643 },
        { lng: -77.087352, lat: -12.040832 },
        { lng: -77.087224, lat: -12.041041 },
        { lng: -77.098253, lat: -12.045999 },
        { lng: -77.098328, lat: -12.046057 },
        { lng: -77.09836, lat: -12.046031 },
        { lng: -77.09845, lat: -12.04602 },
        { lng: -77.098596, lat: -12.046099 },
        { lng: -77.098768, lat: -12.046162 },
        { lng: -77.09894, lat: -12.0462 },
        { lng: -77.098999, lat: -12.04622 },
        { lng: -77.100655, lat: -12.04657 },
        { lng: -77.104195, lat: -12.04702 },
        { lng: -77.10685, lat: -12.04716 },
        { lng: -77.109001, lat: -12.046889 },
        { lng: -77.115807, lat: -12.045779 },
        { lng: -77.115555, lat: -12.049689 },
        { lng: -77.11834, lat: -12.04994 },
        { lng: -77.118141, lat: -12.05388 },
        { lng: -77.115227, lat: -12.05367 },
        { lng: -77.114769, lat: -12.05709 },
        { lng: -77.114746, lat: -12.057085 },
        { lng: -77.114777, lat: -12.05884 },
        { lng: -77.119026, lat: -12.0651 },
        { lng: -77.119067, lat: -12.065132 },
        { lng: -77.119077, lat: -12.065174 },
        { lng: -77.124046, lat: -12.073669 },
        { lng: -77.11097, lat: -12.078919 },
        { lng: -77.110965, lat: -12.078911 },
        { lng: -77.104385, lat: -12.08296 },
        { lng: -77.097015, lat: -12.08577 },
        { lng: -77.085899, lat: -12.090879 },
        { lng: -77.085829, lat: -12.090898 },
        { lng: -77.085743, lat: -12.090961 },
        { lng: -77.083389, lat: -12.09194 },
        { lng: -77.077782, lat: -12.095079 },
        { lng: -77.077761, lat: -12.095031 },
        { lng: -77.077492, lat: -12.095039 },
        { lng: -77.072556, lat: -12.09756 },
        { lng: -77.069817, lat: -12.099959 },
        { lng: -77.067436, lat: -12.10123 },
        { lng: -77.064407, lat: -12.10298 },
        { lng: -77.061424, lat: -12.10503 },
        { lng: -77.061134, lat: -12.104398 },
        { lng: -77.061409, lat: -12.10504 },
        { lng: -77.059891, lat: -12.10615 },
        { lng: -77.057549, lat: -12.10779 },
        { lng: -77.055565, lat: -12.1081 },
        { lng: -77.054626, lat: -12.109719 },
        { lng: -77.054312, lat: -12.10974 },
        { lng: -77.054626, lat: -12.109779 },
        { lng: -77.050125, lat: -12.11342 },
        { lng: -77.049507, lat: -12.11587 },
        { lng: -77.048866, lat: -12.116859 },
        { lng: -77.047607, lat: -12.11759 },
        { lng: -77.04628, lat: -12.11832 },
        { lng: -77.045189, lat: -12.11914 },
        { lng: -77.04409, lat: -12.12184 },
        { lng: -77.040009, lat: -12.124129 },
        { lng: -77.037148, lat: -12.12653 },
        { lng: -77.035507, lat: -12.12891 },
        { lng: -77.037895, lat: -12.13243 },
        { lng: -77.036789, lat: -12.13324 },
        { lng: -77.035057, lat: -12.131169 },
        { lng: -77.033218, lat: -12.13208 },
        { lng: -77.03141, lat: -12.13342 },
        { lng: -77.030838, lat: -12.134069 },
        { lng: -77.029549, lat: -12.13616 },
        { lng: -77.029015, lat: -12.137429 },
        { lng: -77.029488, lat: -12.13777 },
        { lng: -77.028683, lat: -12.13933 },
        { lng: -77.028544, lat: -12.139564 },
        { lng: -77.029731, lat: -12.140103 },
        { lng: -77.029606, lat: -12.140292 },
        { lng: -77.028847, lat: -12.14011 },
        { lng: -77.028805, lat: -12.140179 },
        { lng: -77.015533, lat: -12.134495 },
      ],
      [
        { lng: -76.991782, lat: -12.015559 },
        { lng: -76.991798, lat: -12.01554 },
        { lng: -76.992699, lat: -12.015289 },
        { lng: -76.9944, lat: -12.015289 },
        { lng: -76.994568, lat: -12.01273 },
        { lng: -76.996147, lat: -12.01248 },
        { lng: -76.996895, lat: -12.01063 },
        { lng: -76.996506, lat: -12.00933 },
        { lng: -76.991501, lat: -12.00776 },
        { lng: -76.992188, lat: -12.00574 },
        { lng: -76.992928, lat: -12.00427 },
        { lng: -76.994476, lat: -12.00243 },
        { lng: -76.996185, lat: -12.001909 },
        { lng: -76.99659, lat: -12.0005 },
        { lng: -76.997688, lat: -11.99854 },
        { lng: -76.998955, lat: -11.99653 },
        { lng: -77.000465, lat: -11.996269 },
        { lng: -77.000648, lat: -11.992869 },
        { lng: -76.999435, lat: -11.99031 },
        { lng: -76.998367, lat: -11.98893 },
        { lng: -76.997681, lat: -11.98992 },
        { lng: -76.99691, lat: -11.99006 },
        { lng: -76.995972, lat: -11.98779 },
        { lng: -76.994278, lat: -11.98479 },
        { lng: -76.991997, lat: -11.981199 },
        { lng: -76.99176, lat: -11.979819 },
        { lng: -76.990875, lat: -11.979899 },
        { lng: -76.990608, lat: -11.97939 },
        { lng: -76.990509, lat: -11.97898 },
        { lng: -76.991699, lat: -11.97878 },
        { lng: -76.993156, lat: -11.97798 },
        { lng: -76.995049, lat: -11.97344 },
        { lng: -76.996473, lat: -11.971948 },
        { lng: -76.995019, lat: -11.970444 },
        { lng: -76.997415, lat: -11.968212 },
        { lng: -77.004783, lat: -11.966008 },
        { lng: -77.010549, lat: -11.974257 },
        { lng: -77.008789, lat: -11.975369 },
        { lng: -77.012486, lat: -11.98005 },
        { lng: -77.015366, lat: -11.985 },
        { lng: -77.01593, lat: -11.98709 },
        { lng: -77.015434, lat: -11.990499 },
        { lng: -77.016808, lat: -11.990729 },
        { lng: -77.016747, lat: -11.99198 },
        { lng: -77.015282, lat: -11.9921 },
        { lng: -77.015419, lat: -11.992849 },
        { lng: -77.01535, lat: -11.995649 },
        { lng: -77.014969, lat: -11.99743 },
        { lng: -77.014389, lat: -11.998799 },
        { lng: -77.01368, lat: -12.0004 },
        { lng: -77.012527, lat: -12.0029 },
        { lng: -77.01107, lat: -12.007939 },
        { lng: -77.011681, lat: -12.01229 },
        { lng: -77.013016, lat: -12.02285 },
        { lng: -77.012268, lat: -12.02392 },
        { lng: -77.012032, lat: -12.02516 },
        { lng: -77.010857, lat: -12.02535 },
        { lng: -77.010475, lat: -12.02571 },
        { lng: -77.012207, lat: -12.02975 },
        { lng: -77.012436, lat: -12.03198 },
        { lng: -77.011902, lat: -12.03195 },
        { lng: -77.010338, lat: -12.03055 },
        { lng: -77.00898, lat: -12.02994 },
        { lng: -77.0065, lat: -12.02956 },
        { lng: -77.005249, lat: -12.029429 },
        { lng: -77.003876, lat: -12.02893 },
        { lng: -77.001068, lat: -12.0279 },
        { lng: -76.998077, lat: -12.027169 },
        { lng: -76.996048, lat: -12.02681 },
        { lng: -76.991219, lat: -12.02656 },
        { lng: -76.988583, lat: -12.026467 },
        { lng: -76.985332, lat: -12.025942 },
        { lng: -76.983669, lat: -12.025617 },
        { lng: -76.981394, lat: -12.025218 },
        { lng: -76.981367, lat: -12.024887 },
        { lng: -76.982145, lat: -12.024646 },
        { lng: -76.981899, lat: -12.024147 },
        { lng: -76.982553, lat: -12.023806 },
        { lng: -76.982864, lat: -12.023297 },
        { lng: -76.983529, lat: -12.023156 },
        { lng: -76.983486, lat: -12.02292 },
        { lng: -76.982376, lat: -12.023381 },
        { lng: -76.982102, lat: -12.022752 },
        { lng: -76.982961, lat: -12.022332 },
        { lng: -76.98369, lat: -12.022521 },
        { lng: -76.983792, lat: -12.023103 },
        { lng: -76.986094, lat: -12.02272 },
        { lng: -76.98928, lat: -12.022773 },
        { lng: -76.991115, lat: -12.022332 },
        { lng: -76.990479, lat: -12.02002 },
        { lng: -76.989922, lat: -12.01797 },
        { lng: -76.987701, lat: -12.01878 },
        { lng: -76.986961, lat: -12.019259 },
        { lng: -76.986458, lat: -12.01985 },
        { lng: -76.98632, lat: -12.02084 },
        { lng: -76.98613, lat: -12.02117 },
        { lng: -76.985489, lat: -12.020889 },
        { lng: -76.984924, lat: -12.02048 },
        { lng: -76.982941, lat: -12.01664 },
        { lng: -76.982368, lat: -12.01545 },
        { lng: -76.981911, lat: -12.01458 },
        { lng: -76.983337, lat: -12.01386 },
        { lng: -76.98439, lat: -12.01213 },
        { lng: -76.985756, lat: -12.013 },
        { lng: -76.986229, lat: -12.013619 },
        { lng: -76.986626, lat: -12.01448 },
        { lng: -76.987106, lat: -12.01482 },
        { lng: -76.989525, lat: -12.01641 },
        { lng: -76.990875, lat: -12.015309 },
        { lng: -76.991531, lat: -12.01554 },
        { lng: -76.991782, lat: -12.015559 },
      ],
      [
        { lng: -76.95837, lat: -12.107641 },
        { lng: -76.955795, lat: -12.10932 },
        { lng: -76.95219, lat: -12.107138 },
        { lng: -76.953993, lat: -12.104368 },
        { lng: -76.955967, lat: -12.103193 },
        { lng: -76.959572, lat: -12.104452 },
        { lng: -76.961546, lat: -12.103949 },
        { lng: -76.962318, lat: -12.10227 },
        { lng: -76.962833, lat: -12.099669 },
        { lng: -76.963606, lat: -12.098158 },
        { lng: -76.962833, lat: -12.094381 },
        { lng: -76.96146, lat: -12.093626 },
        { lng: -76.961889, lat: -12.092955 },
        { lng: -76.966267, lat: -12.092955 },
        { lng: -76.965151, lat: -12.089933 },
        { lng: -76.972876, lat: -12.077764 },
        { lng: -77.023001, lat: -12.089178 },
        { lng: -77.043171, lat: -12.099501 },
        { lng: -77.055016, lat: -12.109823 },
        { lng: -77.043772, lat: -12.122159 },
        { lng: -77.037148, lat: -12.12653 },
        { lng: -77.035507, lat: -12.12891 },
        { lng: -77.037895, lat: -12.13243 },
        { lng: -77.036789, lat: -12.13324 },
        { lng: -77.035057, lat: -12.131169 },
        { lng: -77.033218, lat: -12.13208 },
        { lng: -77.03141, lat: -12.13342 },
        { lng: -77.030838, lat: -12.134069 },
        { lng: -77.029549, lat: -12.13616 },
        { lng: -77.029015, lat: -12.137429 },
        { lng: -77.029488, lat: -12.13777 },
        { lng: -77.028564, lat: -12.13954 },
        { lng: -77.02851, lat: -12.139624 },
        { lng: -77.028987, lat: -12.139918 },
        { lng: -77.028912, lat: -12.140038 },
        { lng: -77.028847, lat: -12.14011 },
        { lng: -77.027489, lat: -12.14232 },
        { lng: -77.025902, lat: -12.14452 },
        { lng: -77.027, lat: -12.14576 },
        { lng: -77.026299, lat: -12.14579 },
        { lng: -77.025208, lat: -12.14784 },
        { lng: -77.024879, lat: -12.149389 },
        { lng: -77.025017, lat: -12.15158 },
        { lng: -77.023994, lat: -12.15281 },
        { lng: -77.024445, lat: -12.15473 },
        { lng: -77.024605, lat: -12.156919 },
        { lng: -77.024524, lat: -12.156884 },
        { lng: -77.024948, lat: -12.1626 },
        { lng: -77.026756, lat: -12.16614 },
        { lng: -77.029945, lat: -12.167109 },
        { lng: -77.03302, lat: -12.1671 },
        { lng: -77.033508, lat: -12.16784 },
        { lng: -77.029617, lat: -12.16933 },
        { lng: -77.027367, lat: -12.17112 },
        { lng: -77.022362, lat: -12.17529 },
        { lng: -77.014526, lat: -12.183579 },
        { lng: -77.013161, lat: -12.1837 },
        { lng: -77.011841, lat: -12.1847 },
        { lng: -77.010101, lat: -12.184919 },
        { lng: -77.008118, lat: -12.18405 },
        { lng: -77.007507, lat: -12.18464 },
        { lng: -77.008011, lat: -12.186649 },
        { lng: -77.009575, lat: -12.187099 },
        { lng: -77.025391, lat: -12.20885 },
        { lng: -77.003197, lat: -12.21884 },
        { lng: -76.99852, lat: -12.22104 },
        { lng: -76.989845, lat: -12.224629 },
        { lng: -76.987915, lat: -12.2202 },
        { lng: -76.9802, lat: -12.214797 },
        { lng: -76.996101, lat: -12.20027 },
        { lng: -77.001282, lat: -12.19496 },
        { lng: -77.000481, lat: -12.19399 },
        { lng: -76.999557, lat: -12.19183 },
        { lng: -76.997665, lat: -12.189939 },
        { lng: -76.996574, lat: -12.18742 },
        { lng: -76.996543, lat: -12.187271 },
        { lng: -76.990555, lat: -12.18779 },
        { lng: -76.990639, lat: -12.188869 },
        { lng: -76.986357, lat: -12.191401 },
        { lng: -76.984958, lat: -12.190659 },
        { lng: -76.98186, lat: -12.193513 },
        { lng: -76.981552, lat: -12.193145 },
        { lng: -76.984568, lat: -12.190542 },
        { lng: -76.985931, lat: -12.18968 },
        { lng: -76.987419, lat: -12.18878 },
        { lng: -76.988129, lat: -12.18798 },
        { lng: -76.991196, lat: -12.184219 },
        { lng: -76.991501, lat: -12.18186 },
        { lng: -76.994606, lat: -12.17933 },
        { lng: -76.990407, lat: -12.173658 },
        { lng: -76.990342, lat: -12.173449 },
        { lng: -76.990227, lat: -12.17319 },
        { lng: -76.988327, lat: -12.171539 },
        { lng: -76.988289, lat: -12.170449 },
        { lng: -76.987961, lat: -12.16937 },
        { lng: -76.987946, lat: -12.16713 },
        { lng: -76.986662, lat: -12.165425 },
        { lng: -76.986297, lat: -12.165352 },
        { lng: -76.986115, lat: -12.165394 },
        { lng: -76.985922, lat: -12.165457 },
        { lng: -76.985697, lat: -12.165404 },
        { lng: -76.985514, lat: -12.165499 },
        { lng: -76.985171, lat: -12.165824 },
        { lng: -76.984482, lat: -12.16692 },
        { lng: -76.984409, lat: -12.16702 },
        { lng: -76.984388, lat: -12.167156 },
        { lng: -76.983749, lat: -12.16855 },
        { lng: -76.982948, lat: -12.170119 },
        { lng: -76.98275, lat: -12.171539 },
        { lng: -76.982796, lat: -12.17233 },
        { lng: -76.982635, lat: -12.172649 },
        { lng: -76.981232, lat: -12.17239 },
        { lng: -76.981415, lat: -12.173989 },
        { lng: -76.982712, lat: -12.176089 },
        { lng: -76.983337, lat: -12.179589 },
        { lng: -76.982498, lat: -12.18248 },
        { lng: -76.98175, lat: -12.18693 },
        { lng: -76.974525, lat: -12.18491 },
        { lng: -76.975975, lat: -12.179959 },
        { lng: -76.977242, lat: -12.17674 },
        { lng: -76.970695, lat: -12.17507 },
        { lng: -76.971031, lat: -12.17355 },
        { lng: -76.969376, lat: -12.1732 },
        { lng: -76.964508, lat: -12.18732 },
        { lng: -76.960999, lat: -12.19279 },
        { lng: -76.958977, lat: -12.19253 },
        { lng: -76.956451, lat: -12.19127 },
        { lng: -76.954468, lat: -12.19022 },
        { lng: -76.955986, lat: -12.18769 },
        { lng: -76.957161, lat: -12.18256 },
        { lng: -76.958321, lat: -12.180409 },
        { lng: -76.958611, lat: -12.17546 },
        { lng: -76.962151, lat: -12.17297 },
        { lng: -76.960091, lat: -12.163449 },
        { lng: -76.958672, lat: -12.159599 },
        { lng: -76.95652, lat: -12.160749 },
        { lng: -76.955429, lat: -12.159889 },
        { lng: -76.964272, lat: -12.152929 },
        { lng: -76.969604, lat: -12.14814 },
        { lng: -76.97377, lat: -12.1433 },
        { lng: -76.974754, lat: -12.1412 },
        { lng: -76.974892, lat: -12.14054 },
        { lng: -76.974721, lat: -12.139131 },
        { lng: -76.974699, lat: -12.1389 },
        { lng: -76.974648, lat: -12.13847 },
        { lng: -76.974541, lat: -12.135799 },
        { lng: -76.973579, lat: -12.13556 },
        { lng: -76.96785, lat: -12.13701 },
        { lng: -76.967232, lat: -12.13476 },
        { lng: -76.96711, lat: -12.131829 },
        { lng: -76.962296, lat: -12.13015 },
        { lng: -76.959091, lat: -12.12655 },
        { lng: -76.959129, lat: -12.12366 },
        { lng: -76.959572, lat: -12.119699 },
        { lng: -76.964127, lat: -12.11914 },
        { lng: -76.963936, lat: -12.11628 },
        { lng: -76.96434, lat: -12.114429 },
        { lng: -76.9646, lat: -12.112367 },
        { lng: -76.964706, lat: -12.111529 },
        { lng: -76.964014, lat: -12.111923 },
        { lng: -76.962105, lat: -12.113009 },
        { lng: -76.960441, lat: -12.109212 },
        { lng: -76.960365, lat: -12.109039 },
        { lng: -76.960241, lat: -12.108954 },
        { lng: -76.95837, lat: -12.107641 },
      ],
      [
        { lng: -77.019295, lat: -12.15455 },
        { lng: -77.019298, lat: -12.154551 },
        { lng: -77.019379, lat: -12.15391 },
        { lng: -77.016006, lat: -12.15567 },
        { lng: -77.015936, lat: -12.155418 },
        { lng: -77.015999, lat: -12.155689 },
        { lng: -77.013397, lat: -12.15708 },
        { lng: -77.012939, lat: -12.15602 },
        { lng: -77.0131, lat: -12.15553 },
        { lng: -77.012756, lat: -12.15446 },
        { lng: -77.011627, lat: -12.15209 },
        { lng: -77.006592, lat: -12.15397 },
        { lng: -77.00383, lat: -12.14962 },
        { lng: -77.004227, lat: -12.149139 },
        { lng: -77.003128, lat: -12.14761 },
        { lng: -77.001289, lat: -12.146339 },
        { lng: -77.000809, lat: -12.14707 },
        { lng: -76.999947, lat: -12.14642 },
        { lng: -76.9991, lat: -12.14709 },
        { lng: -76.998871, lat: -12.146899 },
        { lng: -76.996246, lat: -12.14857 },
        { lng: -76.9953, lat: -12.14734 },
        { lng: -76.993065, lat: -12.149099 },
        { lng: -76.994209, lat: -12.150579 },
        { lng: -76.992317, lat: -12.15216 },
        { lng: -76.998039, lat: -12.16058 },
        { lng: -76.999615, lat: -12.165908 },
        { lng: -76.999626, lat: -12.165899 },
        { lng: -77.000877, lat: -12.171289 },
        { lng: -77.002396, lat: -12.17408 },
        { lng: -77.004387, lat: -12.17361 },
        { lng: -77.005455, lat: -12.17161 },
        { lng: -77.007462, lat: -12.169379 },
        { lng: -77.009865, lat: -12.16801 },
        { lng: -77.015335, lat: -12.17242 },
        { lng: -77.018051, lat: -12.169439 },
        { lng: -77.018738, lat: -12.16801 },
        { lng: -77.019348, lat: -12.16748 },
        { lng: -77.018951, lat: -12.16199 },
        { lng: -77.01841, lat: -12.15856 },
        { lng: -77.019295, lat: -12.15455 },
      ]
    ];

  }

  async validateCoverage(lat, lng) {
    var point = new google.maps.LatLng(lat, lng);
    var isValidate = false;


    await Promise.all(
      this.scharffExpressCoverage.map(async (ele) => {
        var poligon = new google.maps.Polygon({ paths: ele });
        var value = google.maps.geometry.poly.containsLocation(point, poligon);
        if (value) {
          isValidate = true;
        }
      })
    );
    return isValidate;
  }


  initMap(map) {
    const element = document.getElementById(map);
    this.map = new google.maps.Map(element, {
      zoom: 14,
      center: this.startPosition,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: true,
      fullscreenControl: true
    });
    this.geocoder = new google.maps.Geocoder();
    this.directionsService = new google.maps.DirectionsService();
    this.directionsRenderer = new google.maps.DirectionsRenderer();
    this.directionsRenderer.setMap(this.map);
    this.directionsRenderer.setOptions({
      polylineOptions: {
        strokeColor: "#ff7177",
      },
      suppressMarkers: true,
    });
  }

  async addMarker(position, title, setLabel, draggedHandler, markerList, id) {
    markerList = markerList || this.markers;
    const vm = this;
    let label = null;
    if (setLabel) label = this.markerLabels[markerList.length];

    this.zIndex += 1;
    const myLatlng = new google.maps.LatLng(
      position.Lat || position.lat,
      position.Lng || position.lng
    );
    const marker = new google.maps.Marker({
      position: myLatlng,
      map: this.map,
      title,
      label: label ? { text: label, color: "white" } : null,
      zIndex: this.zIndex,
      draggable: !!draggedHandler,
      id,
    });
    markerList.push(marker);
    this.centerMap(marker.position);

    if (draggedHandler) marker.addListener("dragend", draggedHandler);

    google.maps.event.addListener(marker, "click", () => {
      if (title) {
        google.maps.event.addListener(marker, "click", () => {
          if (vm.currentInfowindow) {
            vm.currentInfowindow.close();
          }

          const infowindow = new google.maps.InfoWindow({
            content: title,
            position,
          });

          vm.currentInfowindow = infowindow;
          infowindow.open(vm.map, marker);
        });
      }
    });

    return marker;
  }

  async addMarkerScharffPoint(
    position,
    title,
    label,
    scharffPoint,
    btnEvent,
    inputEvent
  ) {
    const vm = this;
    this.zIndex += 1;
    const marker = new google.maps.Marker({
      position: { lat: position.Lat, lng: position.Lng },
      map: this.map,
      title,
      label: label
        ? {
            text: " ",
            fontWeight: "bold",
            fontSize: "12px",
            className: "markerClass",
          }
        : null,
      zIndex: this.zIndex,
    });
    marker.metadata = { type: "point", id: scharffPoint.ScharffPointId };
    this.scharffPointMarkers.push(marker);

    this.centerMap(marker.position);

    const contentString = `
    <div style="display:flex; flex-direction: column;">
    <span style="color: #ff565d;font-weight: bold;font-size: 15px;">${scharffPoint.Name}</span>
    <span style="text-overflow: ellipsis;max-width:300px;">${scharffPoint.Address}</span>
    <span style="color: rgb(116, 116, 116);">Horario de atención: ${scharffPoint.OpenTime.substring(
      0,
      5
    )} - ${scharffPoint.CloseTime.substring(0, 5)}</span>
    </div>
    `;

    const infowindow = new google.maps.InfoWindow({
      content: contentString,
      position: { lat: position.Lat, lng: position.Lng },
    });

    vm.currentInfowindow = infowindow;
    infowindow.open(vm.map, marker);

    google.maps.event.addListener(marker, "click", () => {
      const _inputEvent = inputEvent || "scharffPointMarkerId";
      const _btnEvent = btnEvent || "setSchaffPointMarker";
      const _scharffPointMarket = document.getElementById(_inputEvent);
      _scharffPointMarket.value = scharffPoint.ScharffPointId;
      const _setScharffPoint = document.getElementById(_btnEvent);
      _setScharffPoint.click();
    });
  }

  async addAllMarkersScharffPoint(lstScharffPoint) {
    const vm = this;
    lstScharffPoint.forEach((scharffPoint) => {
      vm.addMarkerScharffPoint(
        scharffPoint.position,
        scharffPoint.title,
        scharffPoint.label,
        scharffPoint.id
      );
    });
  }

  centerMap(position, zoom) {
    this.map.setCenter(position);
    this.map.setZoom(zoom || 15);
  }

  setMarker(position, title, setLabel, draggedHandler, markerList, id) {
    console.log(
      "🚀 ~ file: maps.js:1222 ~ MapComponent ~ setMarker ~ setMarker"
    );
    this.clearMarkers();
    return this.addMarker(
      position,
      title,
      setLabel,
      draggedHandler,
      markerList,
      id
    );
  }

  // clearMarkers() {
  //     for (let i = 0; i < this.markers.length; i++) {
  //         this.markers[i].setMap(null);
  //     }
  // }

  clearMarkers(markerList) {
    markerList = markerList || this.markers;
    while (markerList.length > 0)
      this.removeMarker(markerList[markerList.length - 1], markerList);
  }

  removeMarker(marker, markerList) {
    markerList = markerList || this.markers;
    if (marker) {
      marker.setMap(null);
      const markerIndex = markerList.indexOf(marker);
      markerList.splice(markerIndex, 1);
      // eslint-disable-next-line no-plusplus
      for (let i = markerIndex; i < markerList.length; i++) {
        if (markerList[i].label) {
          markerList[i].setLabel({
            text: this.markerLabels[i],
            color: "white",
          });
        }
      }
    }
  }

  clearMarkersScharffPoint() {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < this.scharffPointMarkers.length; i++) {
      this.scharffPointMarkers[i].setMap(null);
    }
  }

  geocodeLocation(position, geocodeHandler) {
    const geocodeResult = {
      position,
      formatted: "",
      province: "",
      error: false,
    };

    this.geocoder.geocode(
      { location: geocodeResult.position },
      (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            geocodeResult.formatted = results[0].formatted_address;
            let searched = null;
            results.forEach((r) => {
              if (r.types[0] === "colloquial_area") {
                searched = r;
              }
            });
            geocodeResult.province = searched.address_components[0].short_name;
            if (geocodeHandler) geocodeHandler(geocodeResult);
          } else {
            geocodeResult.error =
              "No se pudo encontrar una dirección para la ubicación seleccionada.";
            if (geocodeHandler) geocodeHandler(geocodeResult);
          }
        } else {
          geocodeResult.error =
            "No se pudo procesar la solicitud para encontrar la ubicación seleccionada.";
          if (geocodeHandler) geocodeHandler(geocodeResult);
        }
      }
    );
  }

  getMarkerById(id) {
    return this.markers.find((x) => x.id === id);
  }

  updateMarkerPositionById(id, position) {
    const marker = this.getMarkerById(id);
    if (marker) {
      marker.setPosition(position);
    }
  }

  updateMarkerTitleById(id, title) {
    const marker = this.getMarkerById(id);
    if (marker) {
      marker.setTitle(title);
    }
  }

  renderWaypoint(origin, destinations, getDirectionsHandler) {
    const vm = this;
    const markerList = this.waypointMarkers;

    this.clearMarkers(markerList);
    this.directionsRenderer.setMap(null);

    const start = origin ? origin.location : null;
    const waypoints = destinations.slice(0, -1).map((item) => ({
      location: item.location,
      stopover: true,
    }));
    const end =
      destinations.length > 0 ? destinations.slice(-1).pop().location : null;

    const routeSummary = {
      distance: 0,
      distanceKm: 0,
      time: 0,
      start,
      waypoints,
      end,
      error: false,
    };

    if (!start) {
      routeSummary.error = "Debe seleccionar una dirección de inicio.";
      if (getDirectionsHandler) getDirectionsHandler(routeSummary);
      return;
    }

    if (!end) {
      const marker = this.addMarker(
        origin.location,
        origin.address,
        true,
        null,
        markerList
      );
      this.centerMap(marker.position);
      if (getDirectionsHandler) getDirectionsHandler(routeSummary);
      return;
    }

    this.directionsService.route(
      {
        origin: start,
        waypoints,
        destination: end,
        optimizeWaypoints: false,
        travelMode: "DRIVING",
        region: "PE",
        provideRouteAlternatives: true,
      },
      (response, status) => {
        switch (status) {
          case "ZERO_RESULTS": {
            vm.waypointRequestCounter += 1;
            if (vm.waypointRequestCounter > 3) {
              vm.waypointRequestCounter = 0;

              routeSummary.error =
                "No se pudo obener la información de la ruta.";

              if (getDirectionsHandler) getDirectionsHandler(routeSummary);
            }
            vm.renderWaypoint(origin, destinations, getDirectionsHandler);
            break;
          }
          case "OK": {
            vm.waypointRequestCounter = 0;
            vm.directionsRenderer.setMap(vm.map);

            let shortestDistance = Number.MAX_VALUE;
            let route = response.routes[0];

            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < response.routes.length; i++) {
              let auxDistance = 0;
              // eslint-disable-next-line no-plusplus
              for (let j = 0; j < response.routes[i].legs.length; j++) {
                auxDistance += response.routes[i].legs[j].distance.value;
              }

              if (auxDistance <= shortestDistance) {
                shortestDistance = auxDistance;
                route = response.routes[i];
              }
            }

            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < route.legs.length; i++) {
              routeSummary.distance += route.legs[i].distance.value;
              routeSummary.time += route.legs[i].duration.value;
            }

            vm.addMarker(
              origin.location,
              origin.address,
              true,
              null,
              markerList
            );

            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < destinations.length - 1; i++) {
              vm.addMarker(
                destinations[i].location,
                destinations[i].address,
                true,
                null,
                markerList
              );
            }

            if (
              destinations[destinations.length - 1].location !== origin.location
            ) {
              vm.addMarker(
                destinations[destinations.length - 1].location,
                destinations[destinations.length - 1].address,
                true,
                null,
                markerList
              );
            }

            routeSummary.distanceKm = (routeSummary.distance / 1000).toFixed(2);
            vm.fitMakers(markerList);

            response.routes[0] = route;

            vm.directionsRenderer.setDirections(response);

            if (getDirectionsHandler) getDirectionsHandler(routeSummary);

            break;
          }
          default: {
            routeSummary.error =
              "No se pudo obtener la información de la ruta.";
            if (getDirectionsHandler) getDirectionsHandler(routeSummary);
          }
        }
      }
    );
  }

  fitMakers(markerList) {
    markerList = markerList || this.markers;

    if (markerList.length === 0) {
      return;
    }
    if (markerList.length === 1) {
      this.centerMap(markerList[0].position);
      return;
    }

    const bounds = new google.maps.LatLngBounds();
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < markerList.length; i++) {
      bounds.extend(markerList[i].position);
    }
    this.map.fitBounds(bounds);
  }
}

export default MapComponent;
